import { Scrollbar, Navigation, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";

import VotesTopic from "./../components/VotesTopic";

import 'swiper/css';
import 'swiper/css/navigation';
import { Link } from 'react-router-dom';
import { PageContext } from '../context';
import { useContext, useEffect } from 'react';

export default function AllVotes ({ filteredVotes }) {


    const service = useContext(PageContext)

    useEffect(() => {
        const inter = setInterval(() => {
            service.updateVotes()
        }, 5000)

        return () => clearInterval(inter)
    }, [])

    return (
        <>
            <div className="votes__top">
                <div className="votes__top-container">
                    <h2 className="votes__title title__descr">
                        Discussions
                    </h2>
                    <div className="drops__last">
                        <a href="https://constitution.rgw.zone/dao" target="_blank" className="drops__btn drops__btn-history">
                            read.me
                        </a>
                    </div>
                    <Link to="/dao/voting/new" className="votes__create" style={{
                        display: service.currentAccount == null ? 'none' : 'flex'
                    }}>
                        Create new poll
                    </Link>

                </div>
                
            </div>
            
            <div className="votes__list">
                <Swiper
                    scrollbar={{
                        draggable: true,
                        hide: false,
                        snapOnRelease: true,
                        el: '.votes__scrollbar'
                    }}
                    autoHeight={true}
                    direction={"vertical"}
                    slidesPerView={'auto'}
                    spaceBetween={15}
                    breakpoints={{
                        641: {
                            spaceBetween: 30
                        }
                    }}
                    mousewheel={true}
                    modules={[Scrollbar, Mousewheel]}
                    className="votes__swiper"
                >
                    <div className="votes__scrollbar"/>
                    {filteredVotes.map((vote) => {
                        return (
                            <SwiperSlide key={vote.id} className="vote-topic__item">
                                <VotesTopic key={vote.id} vote={vote}/>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
        </>
    )
}