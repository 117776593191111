import { useContext, useState } from "react"
import { PageContext } from "../context"
import config from "../config"
import api from "../fetch"
import SomeVote from "../pages/SomeVote"
import buyNft from "../contracts/BuyNFT"

export default function SureContainer({ setSureContainer, sure, thisVote, setVotes, updateSomeVote }) {
    const service = useContext(PageContext)

    const [countVotes, setCountVotes] = useState('')
    const [animError, setAnimError] = useState(false)

    const submitForm = async (e) => {
        e.preventDefault()
        if (countVotes !== '' && Number(countVotes) > 0 && Number(countVotes) <= service.remainingVotes ) {
            service.setLoad(true)
            api.post(`/voting/${sure.id}/vote?from=${service.currentAccount}`, {
                vote: sure.vote,
                count: countVotes
            }, service.authCallback).then(async res => {
                await service.fetchVotes().then(setVotes)
                await buyNft.daoDoVote(sure.id, sure.vote, countVotes, res.signature)
                await updateSomeVote(service.currentAccount)
            }).then(() => {
                service.setLoad(false)
                setSureContainer(false)
            }).catch(console.error).finally(() => {
                service.setLoad(false)
            })
        } else if (countVotes !== '' && Number(countVotes) > service.remainingVotes) {
            setAnimError(true)
            setCountVotes(service.remainingVotes)
            setTimeout(() => {
                setAnimError(false)
            }, 500);
        }
    }

    return (
        <div className="sure" onClick={
            (e) => {
                if (e.target === document.querySelector('.sure')) setSureContainer(false)
            }
        }>
            <div className="sure__container">
                <h3 className="sure__title">
                    You have {service.remainingVotes == null ? 0 : service.remainingVotes} votes remaining.
                </h3>
                <p className="sure__descr">
                    How many votes would you like to put on this topic? 
                </p>
                <form action="" className="sure__bottom" onSubmit={submitForm}>
                    <input type="text" className="sure__input" value={countVotes} onChange={(e) => {
                        setCountVotes((oldValue) => {
                            if (e.target.value === '') {
                                return ''
                            } else if (e.target.value.match(/[0-9]*$/)[0] === '') {
                                return oldValue
                            } else {
                                return Number(e.target.value.match(/[0-9]*$/)[0]) <= (service.balanceVotes == null ? 0 : service.balanceVotes) ? Number(e.target.value.match(/[0-9]*$/)[0]) : service.balanceVotes
                            }
                        })
                    }} />
                    <button className="sure__btn" style={{
                        animationPlayState: animError && 'running'
                    }}>
                        Confirm
                    </button>
                </form>
            </div>
        </div>
    )
}