
import { useContext, useEffect, useRef, useState } from "react"
import { PageContext } from "../context"
import api from "../fetch"
import gsap from "gsap"

export default function ModalAgreement() {

    const service = useContext(PageContext)

    const agreementModalRef = useRef(null)

    const agreementModalAnim = useRef()

    useEffect(() => {
        agreementModalAnim.current = gsap.timeline({paused: true}).to(agreementModalRef.current, {
            opacity: 0,
            display: 'none',
            duration: 0.5
        })
    }, [agreementModalRef])

    const submitFetch = async () => {
        service.setLoad(true)
        await api.post('/user/setAgreement', {}, service.authCallback).then( async res => {
            await service.fetchUser(service.currentAccount)
            await service.updateUser()
            service.setLoad(false)
            agreementModalAnim.current.play()
        })
    }

    return (
        <div ref={agreementModalRef} className="agreement">
            <div className="agreement__container">
                <h3 className="agreement__title">
                    By clicking this button, I confirm that I have read and I agree to the terms and conditions
                </h3>
                <a href="https://drive.google.com/file/d/1mc2sRNbzyDvnBTWjksEtFfq9wCDkgtGE/view?usp=drive_link" target="_blank" rel="noreferrer noopener" className="agreement__link">
                    The terms and conditions
                </a>
                <button className="agreement__cancel" onClick={submitFetch}>
                    Agree
                </button>
            </div>
        </div>
    )
}