import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg" 
    xmlSpace="preserve" 
    width={149.565} height={104.884}
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
      fil0: "fill:black; fill-rule:nonzero",
    }}
    viewBox="0 0 14956.5 10488.4"
    {...props}
    >
 <defs>
 </defs>
 <symbol id="q" viewBox="-75478.05 -123766.27 14956.51 9522.2">
 <path className="fil0" d="M-66847.43 -121992.69c372.33,-573.82 817.96,-1014.8 1337.24,-1318.31 516.86,-303.51 1086.03,-455.27 1707.16,-455.27 1066.93,0 1880.25,331.9 2437.38,995.87 561.94,659.15 844.11,1612.18 844.11,2864.25l0 5661.91 -2987.45 0.17 0 -4846.36 0 -218.17c4.82,-80.69 9.46,-194.42 9.46,-341.36 0,-656.74 -99.62,-1130.94 -294.04,-1422.58 -189.61,-296.46 -500.34,-445.8 -929.46,-445.8 -564.35,0 -1000.52,234.68 -1308.84,701.83 -310.57,462.32 -469.55,1133.35 -474.2,2010.67l0 4561.77 -2987.45 -0.17 0 -4846.36c0,-1029.08 -90.16,-1690.64 -265.49,-1982.11 -177.91,-296.46 -493.11,-445.8 -948.38,-445.8 -569,0 -1010.15,234.68 -1318.31,701.83 -310.57,462.32 -464.73,1128.7 -464.73,2001.21l0 4571.24 -2987.62 0 0 -9294.06 2987.62 -0.17 0 1356.17c365.11,-523.92 782.52,-920 1251.9,-1185.48 474.2,-265.49 995.87,-398.31 1564.87,-398.31 644.87,0 1214.04,156.4 1707.16,464.73 493.11,310.57 865.46,746.91 1119.24,1308.84l-0.17 -0.17 0.01 -0.01 -0.01 0z"/>
</symbol>
<symbol id="p" viewBox="-54132.42 -104198.78 7585.32 10488.39">
 <path className="fil0" d="M-46547.1 -98307.68l0 4597.29 -2425.71 0 0 -3503.81c0,-662.34 -15.37,-1116.66 -46.25,-1362.97 -30.88,-246.3 -82.84,-427.36 -153.95,-546.81 -92.35,-153.95 -221.43,-273.41 -385.03,-361.98 -159.83,-86.62 -342.69,-130.9 -546.81,-130.9 -504.34,0 -899,196.42 -1185.96,585.23 -288.77,385.03 -431.27,922.19 -431.27,1609.41l0 3711.69 -2410.34 0.14 0 -10488.39 2410.34 0 0 4042.94c363.79,-435.04 748.82,-758.6 1155.08,-970.25 410.04,-209.84 862.54,-315.73 1355.28,-315.73 872.04,0 1534.38,269.49 1986.75,808.61 450.55,535.21 677.71,1309.04 677.71,2325.68l0.14 -0.14 0.01 0 0.01 -0.01z"/>
</symbol>
<symbol id="l" viewBox="-75838.6 -134140.55 8816.62 10488.41">
 <path className="fil0" d="M-67021.98 -133579.54l0 2599.12c-428.07,-298.08 -863.72,-519.79 -1304.74,-662.54 -435.65,-142.76 -886.84,-214.12 -1355.59,-214.12 -891.83,0 -1587.47,262.39 -2089.51,784.77 -496.86,517.2 -744.08,1243.53 -744.08,2181.22 0,932.7 247.23,1658.84 744.08,2181.22 502.03,517.2 1197.67,774.6 2089.51,774.6 494.27,0 968.38,-71.38 1416.79,-214.12 448.41,-147.74 861.32,-369.45 1243.53,-662.54l0 2609.3c-496.86,183.43 -1004.07,318.6 -1518.68,407.73 -509.62,94.31 -1026.82,142.76 -1549.2,142.76 -1801.6,0 -3210.62,-461.17 -4230.05,-1386.29 -1014.24,-922.53 -1518.68,-2206.74 -1518.68,-3852.83 0,-1651.27 504.62,-2938.07 1518.68,-3863 1019.24,-922.53 2428.45,-1386.29 4230.05,-1386.29 522.38,0 1039.76,48.45 1549.2,142.76 509.62,89.13 1014.24,229.29 1518.68,417.9l0 0.37 0 -0.01 0.01 -0.01z"/>
</symbol>
<symbol id="j" viewBox="-53924.32 -102326.51 7947.45 10488.4">
 <path className="fil0" d="M-48358.83 -98356.49l0 -3970.02 2381.96 0 0 10299.22 -2381.96 0 0 -1073.75c-323.21,438.58 -680.58,760.01 -1073.75,960.44 -393.17,200.43 -850.69,302.49 -1368.7,302.49 -913.11,0 -1661.73,-363 -2245.87,-1088.84 -586.06,-725.98 -877.17,-1657.89 -877.17,-2797.9 0,-1143.72 291.11,-2079.6 877.17,-2805.45 584.13,-725.98 1332.76,-1088.84 2245.87,-1088.84 514.17,0 967.99,103.99 1361.16,310.04 397.02,207.97 758.09,525.56 1081.3,952.76l0 -0.14 -0.01 0 0 -0.01zm-1557.88 4801.77c502.92,0 888.55,-183.41 1157.03,-552.04 266.55,-372.46 400.72,-911.19 400.72,-1618.24 0,-705.14 -134.17,-1242.08 -400.72,-1610.7 -268.48,-372.46 -654.11,-559.58 -1157.03,-559.58 -504.71,0 -890.34,187.12 -1157.03,559.58 -268.48,368.62 -400.72,905.57 -400.72,1610.7 0,707.05 132.38,1245.78 400.72,1618.24 266.55,368.62 652.18,552.04 1157.03,552.04z"/>
</symbol>
<symbol id="h" viewBox="-55858.93 -100884.85 8546.99 10488.41">
 <path className="fil0" d="M-55858.92 -100884.84l2401.45 0 2025.5 5102.3 1718.58 -5102.3 2401.45 0 -3161.01 8232.69c-318.47,832.49 -688.57,1415.57 -1112.55,1749.34 -425.79,337.53 -982.13,506.37 -1672.51,506.37l-1396.36 -0.14 0 -1580.5 751.76 0c408.52,0 705.83,-67.09 889.98,-199.46 187.91,-128.47 333.78,-358.69 437.34,-690.53l69.04 -207.11 -3352.68 -7810.66 0.01 -0.01 0 0.01z"/>
</symbol>
<symbol id="a" viewBox="-77746.36 -134142.68 10723.06 10488.4">
 <path className="fil0" d="M-67023.49 -128934.17l0 917.36 -7471.32 0c81.55,749.09 351.71,1309.76 815.48,1681.8 461.18,374.64 1110.97,560.66 1946.79,560.66 672.72,0 1358.21,-96.9 2058.85,-295.68 705.82,-203.78 1429.58,-504.63 2171.09,-907.19l0 2456.42c-754.27,285.33 -1508.54,499.46 -2262.81,642.22 -754.27,147.74 -1508.54,224.3 -2262.81,224.3 -1809.21,0 -3215.86,-458.59 -4219.77,-1375.95 -998.91,-917.36 -1498.37,-2204.19 -1498.37,-3863.07 0,-1630.77 491.87,-2910.01 1478.03,-3842.73 983.57,-937.7 2336.59,-1406.65 4056.86,-1406.65 1569.75,0 2825.87,476.53 3771.35,1426.99 942.88,945.29 1416.82,2206.78 1416.82,3781.52l-0.19 0 -0.01 0 0.01 0zm-3282.06 -1059.93c0,-603.94 -178.44,-1090.63 -529.97,-1457.5 -354.11,-372.05 -815.48,-560.66 -1386.31,-560.66 -619.28,0 -1121.33,173.26 -1508.54,519.8 -387.21,346.54 -629.46,845.99 -723.76,1498.37l4148.58 0 0 -0.01z"/>
</symbol>
<symbol id="g" viewBox="-61295.37 -108200.59 10994.41 10488.4">
 <path className="fil0" d="M-53682.01 -99623.58l-4224.32 0 -674.57 1911.39 -2714.47 0 3887.04 -10488.4 3220.33 0 3887.04 10488.4 -2714.47 0 -666.56 -1911.39 -0.02 0zm-3549.75 -1943.44l2867.16 0 -1429.43 -4176.1 -1437.74 4176.1 0.01 0z"/>
</symbol>
<symbol id="r" viewBox="-53309.17 -104200.36 5860.32 10488.4">
 <path className="fil0" d="M-47448.85 -104200.35l0 1586.38 -1332.25 0.14c-338.79,0 -577.55,61.61 -716.14,184.83 -134.82,123.22 -200.2,338.79 -200.2,646.84l0 523.62 2063.76 0 0 1724.97 -2063.76 0 0 5821.61 -2410.38 0 0 -5821.61 -1201.35 0 0 -1724.97 1201.35 0 0 -523.62c0,-820.23 227.16,-1428.51 685.41,-1825.14 456.29,-394.67 1166.7,-592.92 2133.2,-592.92l1840.36 -0.14 0 0.01z"/>
</symbol>
<symbol id="i" viewBox="-53397.09 -102329.17 7947.65 10488.39">
 <path className="fil0" d="M-49457.16 -93557.15c508.56,0 896.12,-183.42 1164.6,-552.05 266.56,-372.47 400.73,-911.21 400.73,-1618.29 0,-705.15 -134.17,-1242.11 -400.73,-1610.74 -268.48,-372.47 -656.04,-559.6 -1164.6,-559.6 -510.48,0 -899.96,187.12 -1172.15,559.6 -268.48,374.39 -400.73,911.21 -400.73,1610.74 0,701.31 132.39,1238.27 400.73,1610.74 272.19,374.39 661.67,559.6 1172.15,559.6l0 0.01 0 -0.01zm-1573.01 -4802.04c327.06,-427.2 688.14,-744.93 1081.33,-952.78 397.03,-206.06 854.55,-310.05 1368.74,-310.05 916.84,0 1667.4,363.01 2253.47,1088.87 584.15,726 877.19,1661.78 877.19,2805.52 0,1140.05 -293.03,2071.98 -877.19,2797.98 -586.07,726 -1336.64,1088.87 -2253.47,1088.87 -514.18,0 -971.72,-102.07 -1368.74,-302.5 -393.19,-206.06 -754.27,-525.57 -1081.33,-960.46l0 1073.78 -2366.92 0.28 0 -10299.49 2366.92 0 0 3969.98 0 0.02 0 -0.02z"/>
</symbol>
<symbol id="e" viewBox="-58444.38 -108200.59 9396.25 10488.41">
 <polygon className="fil0" points="-58444.38,-108200.59 -55424.68,-108200.59 -51609.9,-101004.78 -51609.9,-108200.59 -49048.13,-108200.59 -49048.13,-97712.18 -52067.69,-97712.18 -55882.46,-104907.99 -55882.46,-97712.18 -58444.38,-97712.18 "/>
</symbol>
<symbol id="u" viewBox="-51746.58 -108200.59 2698.45 10488.41">
 <polygon className="fil0" points="-51746.58,-108200.59 -49048.13,-108200.59 -49048.13,-97712.18 -51746.58,-97712.18 "/>
</symbol>
<symbol id="o" viewBox="-78590.41 -137086.67 10279.77 10488.41">
 <path className="fil0" d="M-78590.41 -130845.73l0 -6240.94 3287.36 0.19 0 1022.77c0,558.34 -5.3,1252.43 -10.41,2087.19 -7.76,834.96 -10.41,1393.29 -10.41,1669.72 0,829.65 20.83,1424.53 62.67,1784.64 46.95,362.57 120.04,623.47 219.25,782.7 138.21,216.59 315.61,383.58 532.21,500.97 213.95,112.28 461.78,166.99 741.05,166.99 681.02,0 1215.88,-260.9 1607.23,-782.7 388.7,-521.8 584.47,-1247.12 584.47,-2181.1l0 -5051.17 3266.35 0 0 10227.12 -3266.54 0 0 -1481.9c-495.67,599.99 -1017.47,1040.95 -1565.39,1325.51 -550.57,276.61 -1158.52,417.48 -1826.29,417.48 -1176.7,0 -2074.32,-360.11 -2692.48,-1085.44 -621.01,-722.68 -928.87,-1776.88 -928.87,-3162.21l-0.19 0.19 -0.01 0 0 -0.01z"/>
</symbol>
<symbol id="t" viewBox="-55005.88 -104487.73 7973.07 10488.4">
 <path className="fil0" d="M-47690.69 -103984.87l0 2136.47c-557.36,-247.71 -1103.06,-433.5 -1633.32,-557.36 -526.32,-127.65 -1025.68,-193.51 -1493.87,-193.51 -619.29,0 -1079.75,89.03 -1377.74,263.17 -299.96,170.34 -448.95,437.29 -448.95,797.35 0,268.92 100.68,479.98 301.92,634.73 201.24,150.96 565.08,278.61 1091.4,387.02l1114.57 224.54c1124.26,222.58 1921.62,565.08 2391.76,1029.47 474.09,460.61 712.11,1112.75 712.11,1958.41 0,1114.57 -330.85,1946.75 -990.86,2492.45 -661.83,541.91 -1669.97,812.8 -3026.64,812.8 -646.39,0 -1290.81,-61.93 -1935.1,-185.78 -646.39,-118.1 -1290.81,-296.02 -1935.1,-534.05l0 -2206.12c644.42,346.43 1269.47,607.63 1873.17,781.76 603.84,170.34 1184.37,255.44 1741.59,255.44 572.81,0 1008.28,-92.83 1308.08,-278.61 303.89,-189.58 456.67,-464.39 456.67,-820.52 0,-313.44 -104.48,-557.36 -309.64,-727.7 -207.13,-170.34 -617.32,-325.09 -1230.71,-464.39l-1006.3 -216.68c-1012.06,-216.68 -1753.24,-561.15 -2221.57,-1037.2 -464.39,-474.09 -696.66,-1114.57 -696.66,-1919.64 0,-1006.3 325.09,-1780.34 975.27,-2322.11 650.18,-541.77 1582.9,-812.8 2802.1,-812.8 557.36,0 1126.23,42.55 1710.69,123.86 588.25,83.28 1195.88,208.95 1826.83,379.29l0.28 -0.28 0.02 -0.02 0 0.01z"/>
</symbol>
<symbol id="d" viewBox="-77999.29 -134140.56 10977.3 10488.41">
 <path className="fil0" d="M-72495.38 -131857.46c-708.4,0 -1248.71,254.81 -1620.56,764.43 -374.63,509.62 -560.65,1243.53 -560.65,2201.56 0,958.03 186.02,1691.94 560.65,2201.56 372.05,504.62 912.17,754.26 1620.56,754.26 693.05,0 1223.19,-249.63 1590.06,-754.26 372.05,-509.62 560.65,-1243.53 560.65,-2201.56 0,-958.03 -188.61,-1691.94 -560.65,-2201.56 -366.86,-509.62 -897.01,-764.43 -1590.06,-764.43zm0 -2283.1c1717.46,0 3060.29,466.35 4026.09,1396.46 963.2,924.93 1447.3,2209.33 1447.3,3852.83 0,1638.51 -484.1,2922.71 -1447.3,3852.83 -965.79,924.93 -2308.63,1386.29 -4026.09,1386.29 -1727.63,0 -3075.64,-461.17 -4046.43,-1386.29 -973.38,-930.1 -1457.48,-2214.32 -1457.48,-3852.83 0,-1643.49 484.1,-2927.9 1457.48,-3852.83 970.79,-930.1 2318.8,-1396.46 4046.43,-1396.46z"/>
</symbol>
<symbol id="n" viewBox="-78569.52 -137475.11 10290.16 10488.4">
 <path className="fil0" d="M-68279.36 -133223.34l0 6236.63 -3290.69 0 0 -4774.08c0,-882.79 -20.85,-1493.82 -62.74,-1828.14 -41.89,-334.32 -112.39,-579.75 -208.85,-741.8 -125.28,-208.85 -300.39,-370.9 -522.32,-491.06 -216.82,-117.5 -464.9,-177.58 -741.8,-177.58 -684.18,0 -1219.57,266.47 -1608.86,793.91 -391.74,522.32 -585.06,1251.04 -585.06,2183.3l0 5035.24 -3269.84 0.19 0 -10237.46 3269.84 -0.19 0 1493.82c493.52,-590.18 1015.84,-1029.11 1566.98,-1316.23 556.25,-284.66 1170.12,-428.32 1838.56,-428.32 1183,0 2081.53,365.59 2695.21,1096.95 611.21,726.07 919.38,1775.83 919.38,3154.99l0.19 -0.19 -0.01 0.01 0.01 0.01z"/>
</symbol>
<symbol id="f" viewBox="-57680.53 -108199.1 8633.28 10488.4">
 <path className="fil0" d="M-57680.52 -108199.09l4489.28 0c1333.1,0 2355.01,297.07 3067.89,891.5 716.67,590.2 1076.1,1433.49 1076.1,2529.69 0,1098.24 -359.42,1943.42 -1076.1,2537.71 -712.73,594.29 -1734.64,891.5 -3067.89,891.5l-1790.88 0 0 3637.99 -2698.41 0 0 -10488.4 0.01 0.01zm2698.42 1959.59l0 2931.23 1501.82 0c524.06,0 927.63,-128.51 1212.62,-385.51 289.06,-257 433.74,-618.32 433.74,-1084.11 0,-469.87 -144.53,-831.19 -433.74,-1084.11 -285.12,-251.03 -688.69,-377.5 -1212.62,-377.5l-1501.82 0z"/>
</symbol>
<symbol id="s" viewBox="-58444.38 -108200.59 9396.25 10488.41">
 <polygon className="fil0" points="-58444.38,-108200.59 -55745.94,-108200.59 -55745.94,-104209.24 -51754.58,-104209.24 -51754.58,-108200.59 -49048.13,-108200.59 -49048.13,-97712.18 -51754.58,-97712.18 -51754.58,-102161.32 -55745.94,-102161.32 -55745.94,-97712.18 -58444.38,-97712.18 "/>
</symbol>
<symbol id="c" viewBox="-75883.26 -137473.07 7605.06 10488.41">
 <path className="fil0" d="M-68278.2 -134433.35c-292.43,-130.58 -582.39,-229.89 -867.06,-292.43 -279.54,-67.85 -561.55,-104.43 -846.21,-104.43 -843.55,0 -1491.33,271.59 -1942.96,814.93 -454.47,538.05 -679.05,1305.79 -679.05,2308.73l0 4721.89 -3269.78 -0.19 0 -10237.29 3269.78 -0.19 0 1681.79c417.89,-668.62 898.52,-1154.36 1441.68,-1462.52 548.47,-313.46 1204.02,-470.01 1963.99,-470.01 109.73,0 229.89,7.77 355.16,20.85 130.58,7.77 318.58,26.16 564.2,52.31l10.23 2966.55 0.01 0.01 0.01 0z"/>
</symbol>
<symbol id="m" viewBox="-37469.36 -81229.34 3142.27 10488.41">
 <path className="fil0" d="M-36204.09 -78938.48l1877 0 0 5768.86c0,787.12 -190.39,1388.31 -569.75,1804.98 -376.31,415.27 -923.54,623.71 -1643.1,623.71l-929.42 0 0 -1235.34 329.75 0c355.32,0 599.67,-80.95 731.64,-239.88 134.9,-160.47 203.88,-478.25 203.88,-953.46l0 -5768.86 -0.01 0 0.01 -0.01zm0 -2290.86l1877 0 0 1535.18 -1877 0 0 -1535.18z"/>
</symbol>
<symbol id="k" viewBox="-77124.67 -134142.7 10101.22 10488.41">
 <path className="fil0" d="M-71895.82 -128404.2c-667.54,0 -1169.59,112.05 -1508.54,336.36 -333.78,224.3 -499.46,558.08 -499.46,998.91 0,407.73 134.99,728.75 407.73,958.23 270.16,224.3 644.61,336.36 1121.33,336.36 603.94,0 1110.97,-214.13 1518.71,-642.22 412.73,-433.25 621.68,-973.39 621.68,-1620.59l0 -366.87 -1661.46 -0.19 0.01 0.01zm4872.15 -1202.69l0 5697.79 -3210.69 0 0 -1478.03c-428.08,606.53 -912.19,1047.36 -1447.33,1325.11 -537.73,270.16 -1189.93,407.73 -1956.96,407.73 -1034.6,0 -1873.01,-298.09 -2517.62,-897.03 -647.2,-603.94 -968.4,-1388.71 -968.4,-2354.53 0,-1167.19 400.16,-2023.34 1202.88,-2568.66 807.71,-550.49 2074.2,-825.65 3801.86,-825.65l1885.4 0.19 0 -254.81c0,-502.04 -201.37,-868.91 -601.35,-1100.81 -402.56,-237.06 -1024.43,-356.7 -1865.24,-356.7 -688.07,0 -1325.11,68.79 -1916.27,203.78 -585.99,137.57 -1128.91,341.54 -1630.77,611.51l0 -2436.07c677.9,-163.09 1360.8,-287.92 2048.68,-377.04 693.06,-86.73 1386.31,-132.59 2079.38,-132.59 1799.04,0 3098.63,356.7 3893.77,1070.29 800.13,708.41 1202.88,1862.84 1202.88,3465.5l-0.19 0 0 0.01 -0.03 0.01z"/>
</symbol>
<symbol id="v" viewBox="-55949.05 -104487.64 8607.37 10488.4">
 <path className="fil0" d="M-47341.68 -94750.11c-479.98,247.71 -981.16,433.5 -1501.59,557.36 -516.63,127.65 -1058.55,193.51 -1625.45,193.51 -1683.59,0 -3016.81,-468.32 -4001.91,-1408.77 -986.93,-944.37 -1478.42,-2221.57 -1478.42,-3831.57 0,-1613.93 491.49,-2891.13 1478.42,-3831.57 984.96,-944.37 2318.32,-1416.49 4001.91,-1416.49 567.04,0 1108.82,65.86 1625.45,193.51 520.57,123.86 1021.75,309.64 1501.59,557.36l0 2089.98c-485.74,-325.09 -963.76,-565.08 -1431.94,-719.83 -470.29,-154.75 -965.58,-232.27 -1486.15,-232.27 -928.93,0 -1660.42,299.96 -2190.53,897.9 -532.22,594.15 -797.35,1414.67 -797.35,2461.56 0,1043.09 265.13,1863.48 797.35,2461.56 530.25,594.15 1261.74,890.17 2190.53,890.17 520.57,0 1015.99,-73.58 1486.15,-224.54 468.32,-154.75 946.34,-396.71 1431.94,-727.7l0 2089.84 0 0.01 0 -0.02z"/>
</symbol>
<symbol id="b" viewBox="-59246.9 -111912.6 6603.16 10488.41">
 <path className="fil0" d="M-55336.67 -111912.6l0 2317.82 2692.93 0 0 1867.53 -2692.93 0 0 3468.41c0,379.35 75.02,633.6 225.06,767.01 150.05,133.41 446.05,200.11 892.09,200.11l1342.38 0 0 1867.53 -2234.48 0c-1033.82,0 -1767.55,-212.66 -2201.05,-641.92 -429.41,-433.5 -641.92,-1162.99 -641.92,-2192.73l0 -3468.41 -1292.31 0 0 -1867.53 1292.31 0 0 -2317.82 2617.9 0 0.02 0z"/>
</symbol>
 <g id="Слой_x0020_1">
  <metadata id="CorelCorpID_0Corel-Layer"/>
  <path className="fil1" fill="#E22C2C" d="M13626.99 232.26l-8389.2 8389.18 -3920.66 -3920.68 0.82 -0.82c-151.29,-150.8 -349.92,-226.22 -548.94,-226.22 -198.34,0 -396.67,75.69 -548.02,227.04l-0.22 0.22 -0.82 -0.82c-150.8,151.29 -226.21,349.93 -226.21,548.95 0,198.34 75.68,396.68 227.03,548.03l4468.73 4468.73c75.12,75.12 160.86,131.59 251.43,169.17 93.67,38.87 194.8,58.48 296.73,58.48 102.38,0 203.42,-19.32 296.89,-58.1 90.01,-37.35 175.53,-93.79 251.29,-169.55l8937.32 -8937.32c151.36,-151.4 227.06,-349.77 227.06,-548.14 0,-198.34 -75.69,-396.7 -227.06,-548.06l-0.08 -0.08c-151.43,-151.45 -349.79,-227.15 -548.13,-227.15 -193.21,0 -386.45,71.84 -536.28,215.46l-11.68 11.68z"/>
 </g>
</svg>

)

export default SvgComponent
