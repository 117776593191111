import gameplayImg from './../assets/img/gameplay.webp'
import punishmentsImg from './../assets/img/punishments.webp'
import websiteImg from './../assets/img/website.webp'
import flagImg from './../assets/img/flag.webp'
import starImg from './../assets/img/star.webp'
import tradeUpImg from './../assets/img/trade-up.webp'
import walletImg from './../assets/img/wallet.webp'
import { generateUrl, safeNavigate } from '../utils'
import { useLocation } from 'react-router'

export default function Topics({votes, navigate, search, topic, filteredVotes}) {

    const location = useLocation().pathname

    const TOPICS = {
        GAMEPLAY: 'gameplay',
        ECONOMY: 'economy',
        DEVELOPMENT: 'development',
        PUNISHMENTS: 'punishments',
        WEBSITE: 'website',
        ALL: 'all',
        OTHERS: 'others',
    }

    function switchTopicVote(voteTopic) {
        for (let i = 0; i < votes.length; i++) {
            if (voteTopic == 'all' || votes[i].topic === voteTopic) {
                window.localStorage.setItem('topic', voteTopic)
                safeNavigate(navigate, generateUrl(`/dao/voting/:topic/:voteId`, {
                    topic: voteTopic,
                    voteId: ''
                }), search)
                break
            }
        }
    }

    return (
        <div className="topics">
            <h2 className="topics__title">
                Topics
            </h2>
            <ul className="topics__list">
                <li className="topics__item themes__item1">
                    <div className="topics__link">
                        <img className="topics__item-icon" src={flagImg} alt='' />
                        <p className="topics__item-descr" onClick={(e) => {
                            // for (let i = 0; i < votes.length; i++) {
                            //     if (votes[i].type === 'general') {
                            //         break
                            //     }
                            // }
                            switchTopicVote(TOPICS.ALL)
                        }} style={{
                            color: location.indexOf(TOPICS.ALL) !== -1 ? '#ffffff' : '#ffffff60'
                        }}>
                            All topics {`(${votes.length})`}
                        </p>
                    </div>
                </li>
                <li className="topics__item themes__item2">
                    <div className="topics__link">
                        <img className="topics__item-icon" src={gameplayImg} alt='' />
                        <p className="topics__item-descr" style={{
                            color: location.indexOf(TOPICS.GAMEPLAY) !== -1 ? '#ffffff' : '#ffffff60'
                        }} onClick={(e) => {
                            switchTopicVote(TOPICS.GAMEPLAY)
                        }}>
                            Gameplay {`(${votes.filter((x) => x.topic === 'gameplay').length})`}
                        </p>
                    </div>
                </li>
                <li className="topics__item themes__item3">
                    <div className="topics__link">
                        <img className="topics__item-icon" src={walletImg} alt='' />
                        <p className="topics__item-descr" onClick={(e) => {
                            switchTopicVote(TOPICS.ECONOMY)
                        }} style={{
                            color: location.indexOf(TOPICS.ECONOMY) !== -1 ? '#ffffff' : '#ffffff60'
                        }}>
                            Economy {`(${votes.filter((x) => x.topic === 'economy').length})`}
                        </p>
                    </div>
                </li>
                <li className="topics__item themes__item4">
                    <div className="topics__link">
                        <img className="topics__item-icon" src={tradeUpImg} alt='' />
                        <p className="topics__item-descr" onClick={(e) => {
                            switchTopicVote(TOPICS.DEVELOPMENT)
                        }} style={{
                            color: location.indexOf(TOPICS.DEVELOPMENT) !== -1 ? '#ffffff' : '#ffffff60'
                        }}>
                            Development {`(${votes.filter((x) => x.topic === 'development').length})`}
                        </p>
                    </div>
                </li>
                <li className="topics__item themes__item5">
                    <div className="topics__link">
                        <img className="topics__item-icon" src={punishmentsImg} alt='' />
                        <p className="topics__item-descr" onClick={(e) => {
                            switchTopicVote(TOPICS.PUNISHMENTS)
                        }} style={{
                            color: location.indexOf(TOPICS.PUNISHMENTS) !== -1 ? '#ffffff' : '#ffffff60'
                        }}>
                            Punishments {`(${votes.filter((x) => x.topic === 'punishments').length})`}
                        </p>
                    </div>
                </li>
                <li className="topics__item themes__item6">
                    <div className="topics__link">
                        <img className="topics__item-icon" src={websiteImg} alt='' />
                        <p className="topics__item-descr" onClick={(e) => {
                            switchTopicVote(TOPICS.WEBSITE)
                        }} style={{
                            color: location.indexOf(TOPICS.WEBSITE) !== -1 ? '#ffffff' : '#ffffff60'
                        }}>
                            Website {`(${votes.filter((x) => x.topic === 'website').length})`}
                        </p>
                    </div>
                </li>
                <li className="topics__item themes__item7">
                    <div className="topics__link">
                        <img className="topics__item-icon" src={starImg} alt='' />
                        <p className="topics__item-descr" onClick={(e) => {
                            switchTopicVote(TOPICS.OTHERS)
                        }} style={{
                            color: location.indexOf(TOPICS.OTHERS) !== -1 ? '#ffffff' : '#ffffff60'
                        }}>
                            Other {`(${votes.filter((x) => x.topic === 'others').length})`}
                        </p>
                    </div>
                </li>
            </ul>
        </div>
    )
}