
import styles from './AdminMLM.module.sass'

import { truncateAddress } from '../../utils'

import defaultAvatar from '../../assets/img/defaultAvatar.png'
import { useEffect, useState } from 'react'
import api from '../../fetch'
import buyNft from '../../contracts/BuyNFT'

export default function AdminMLMUser({x, i, updateAllUsers}) {

    const [lvl, setLvl] = useState(x.mlm.level)
    const [block, setBlock] = useState(x.mlm.blacklisted != null ? x.mlm.blacklisted : false)

    useEffect(() => {
        setLvl(x.mlm.level)
        x.mlm.blacklisted != null && setBlock(x.mlm.blacklisted)
    }, [x])

    const updateUser = async () => {
        await api.post("", {
            lvl,
            block,
            _id: x._id
        }).then(async res => {
            await updateAllUsers()
        })
    }

    const onBlurLvl = async () => {
        await buyNft.setLevel(x.address, lvl).then(async res => {
            await updateAllUsers()
        }).catch(err => {
            setLvl(x.mlm.level)
        })
    }

    useEffect(() => {
        x.mlm.blacklisted != null && x.mlm.blacklisted != block && buyNft.setBlacklisted(x.address, block).then(async res => {
            await updateAllUsers()
        }).catch(err => {
            setBlock(x.mlm.blacklisted != null ? x.mlm.blacklisted : false)
        })
    }, [block])

    const handleCopyClick = (address) => {
        navigator.clipboard.writeText(address)
    }

    return (
        <li className={styles.admin__user}> 
            <p className={styles.admin__user_index}>
                #{i+1}
            </p>
            <div className={styles.admin__user_avatar} style={{
                backgroundImage: x.avatar != null && x.avatar !== 'url(undefined)' ? x.avatar : `url(${defaultAvatar})`
            }} />
            <p className={styles.admin__user_nickname}>
                {x.name != null && x.name}
            </p>
            <input type="text" className={styles.admin__user_lvl} value={lvl} onChange={(e) => {
                setLvl(e.target.value)
            }} onBlur={onBlurLvl} />
            <p className={styles.admin__user_role} style={{
                color: x.displayRole != null ? x.displayRole === 'Superadmin' ? '#ffff00' : (x.displayRole === 'Duke' ? '#f40101' : (x.displayRole === 'Earl' ? '#00ffff' : (x.displayRole === 'Baron' ? '#00ff00' : '#efefef'))) : '#ffffff'
            }}>
                {x.displayRole != null ? x.displayRole : 'Guest'}
            </p>
            <p className={styles.admin__user_verif}>
                {x != null && x.email != null && x.email} ({x != null && x.verified != null && x.verified ? "Verified" : "Not verified"})
            </p>
            <p className={styles.admin__user_referrals}>
                {x.mlm.volume}
            </p>
            <p className={styles.admin__user_mentor}>
                {truncateAddress(x.mlm.inviter)}
                <button onClick={() => {handleCopyClick(x.inviter)}}>
                    <svg className="right-content__address-svg" width="14" height="14" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <use className="footer-socials__svg" xlinkHref="/assets/sprite.svg#copy"></use>
                    </svg>
                </button>
            </p>
            <p className={styles.admin__user_amount}>
                {x.mlm.balance}
            </p>
            {/* <p className={styles.admin__user_amount}>
                {x.mlm.profitMenter}
            </p> */}
            <p className={styles.admin__user_amount}>
                {x.mlm.totalPayout + x.mlm.balance}
            </p>
            <input type="checkbox" className={styles.admin__user_checkbox} checked={block} value={block} onChange={(e) => {
                setBlock(e.target.checked)
            }} />
            <p className={styles.admin__user_amount}>
                {x.mlm.totalPayout}
            </p>
            {/* <p className={styles.admin__user_amount}>
                {x.mlm.balance}
            </p> */}
        </li>
    )
}