import api from "../fetch"


export default function SuccessModal({successMode, successRef, animSuccess, successCount, successPrice, name, finishDate}) {
    return (
        <div ref={successRef} className="success modal" onClick={(e) =>{
            if (document.querySelector('.success') === e.target) {
                animSuccess.current.reverse()
            }
        }}>
            <div className="success__container">
                <h5 className="success__title">
                    {successMode === 'submit' ? (
                        `You successfully added an order for ${successCount} units of ${name} in price category ${successPrice} USDT to the Order Book.`
                    ) : (
                        successMode === 'cancel' ? (
                            `You have canceled your order.`
                        ) : (
                            successMode === 'payed' ? (
                                `You have successfully paid your order for ${successCount} units of ${name}.`
                            ) : (
                                ''
                            )
                        )
                    )}
                </h5>
                {successMode === 'submit' ? (
                    <p className="success__descr" dangerouslySetInnerHTML={{__html: `The Order Book will be open until ${new Date(finishDate).toLocaleString('ru-RU')}. Before the specified date, you can modify or cancel your order. Once the Order Book closes, the Administrator will select top offers. If yours is chosen, its status changes to "Approved", and you can pay for the box.<br/> <br/>If you don't open the box, you can return it for an automatic refund, minus a 20% fee.`}}/>
                ) : (
                    successMode === 'cancel' ? (
                        <p className="success__descr">
                            {`We are collecting orders until ${new Date(finishDate).toLocaleString('ru-RU')}. Before that date, you can add another Order to the Order Book.`}
                        </p>
                    ) : (
                        successMode === 'payed' ? (
                            <p className="success__descr" dangerouslySetInnerHTML={{__html: `Now, you will receive an NFT box with Raider and Village cards. You will find this box in the "inventory" section. You can return it to us with a 20% deduction. You can open it to obtain the cards. The cards you receive can be transferred to DAO mode. Also,If you open at least one box on your account, you will gain access to the referral system.`}}/>
                        ) : (
                            ''
                        )
                    )
                )}
                <button className="success__btn" onClick={() => {
                    animSuccess.current.reverse()
                }}>
                    Okay
                </button>
            </div>
        </div>
    )
}