import React, { useEffect, useState } from 'react'
import api from '../../fetch'
import list from '../../assets/img/list.webp'

import styles from './AdminMLM.module.sass'

import AdminMLMUser from './AdminMLMUser'
import buyNft from '../../contracts/BuyNFT'

export default function AdminMLM({currentAccount, role}) {

    const [data, setData] = useState(null)

    const [grid, setGrid] = useState([[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]])

    const [smr, setSMR] = useState(0)
    const [earlR, setEarlR] = useState(0)
    const [earlV, setEarlV] = useState(0)

    const updateCellValue = (row, col, value) => {
        setGrid(prevGrid => {
            const newGrid = prevGrid.map(rowValues => [...rowValues]);
            newGrid[row][col] = value;
            return newGrid;
        })
    }

    const handleBlurCeil = (rowIndex, colIndex, value) => {
        if (!value || isNaN(value)) {
          updateCellValue(rowIndex, colIndex, 0);
        }
    }

    const updateAllUsers = async () => {
      api.get("/admin/mlm?offset=0&count=1000").then(res => {
        setData(res.users)
      })
    }

    useEffect(() => {
        async function dataTable() {
            const res = await buyNft.mlmInfo()
            setSMR(res._smrReward)
            setGrid(res._levels.map(item => [item.reward, item.volume]))
            setEarlR(res._earlR)
            setEarlV(res._earlV)
        }

        updateAllUsers()
        dataTable()
    }, [])

    const sumbitForm = async (e) => {
      e.preventDefault()
      await buyNft.mlmUpdate(grid, smr, earlV, earlR)
    }

    return (
        <div className={styles.admin}>
            <form className={styles.admin__form} onSubmit={sumbitForm}>
                <div className={styles.admin__form_block}>
                    <ul className={styles.admin__settings}>
                        <li className={styles.admin__setting}>
                            <p className={styles.admin__setting_title}>
                                SMR
                            </p>
                            <input type="text" className={styles.admin__setting_input} value={smr} onChange={(e) => {
                                setSMR(e.target.value)
                            }} />
                        </li>
                        <li className={styles.admin__setting}>
                            <p className={styles.admin__setting_title}>
                                Earl V
                            </p>
                            <input type="text" className={styles.admin__setting_input} value={earlV} onChange={(e) => {
                                setEarlV(e.target.value)
                            }} />
                        </li>
                        <li className={styles.admin__setting}>
                            <p className={styles.admin__setting_title}>
                                Earl R
                            </p>
                            <input type="text" className={styles.admin__setting_input} value={earlR} onChange={(e) => {
                                setEarlR(e.target.value)
                            }} />
                        </li>
                    </ul>
                    <ul className={styles.admin__grid}>
                        <p className={styles.admin__field}>
                            Level
                        </p>
                        <p className={styles.admin__field}>
                            V
                        </p>
                        <p className={styles.admin__field}>
                            R
                        </p>
                        {grid.map((row, rowIndex) => (
                            <React.Fragment key={rowIndex}>
                                <p className={styles.admin__field}>
                                    {`L${rowIndex + 1}`}
                                </p>
                                {row.map((cell, colIndex) => (
                                    <input
                                        key={colIndex}
                                        className={styles.admin__field}
                                        type="text"
                                        value={cell}
                                        onChange={(e) => updateCellValue(rowIndex, colIndex, e.target.value)}
                                        onBlur={(e) => handleBlurCeil(rowIndex, colIndex, e.target.value)}
                                    />
                                ))}
                            </React.Fragment>
                        ))}
                    </ul>
                </div>
                <button className={styles.admin__form_submit}>
                    Update
                </button>
            </form>
            <div className={styles.admin__bottom}>
                <div className={styles.admin__bottom_top}>
                    <img src={list} alt="" className={styles.admin__bottom_icon} />
                    <h3 className={styles.admin__bottom_title}>
                        All users
                    </h3>
                </div>
                <ul className={styles.admin__users}>
                    <li className={styles.admin__user}>
                        <div className={styles.admin__user_index} />
                        <div className={styles.admin__user_avatar} />
                        <div className={styles.admin__user_nickname} />
                        <p className={styles.admin__user_lvl}>
                            MLM L
                        </p>
                        <div className={styles.admin__user_role}/>
                        <div className={styles.admin__user_verif}/>
                        <p className={styles.admin__user_referrals}>
                            Vcurrent
                        </p>
                        <p className={styles.admin__user_mentor}>
                            Mentor
                        </p>
                        <p className={styles.admin__user_amount}>
                            Payout amount
                        </p>
                        {/* <p className={styles.admin__user_amount}>
                            Payout amount 2
                        </p> */}
                        <p className={styles.admin__user_amount}>
                            Expected Payout
                        </p>
                        <div className={styles.admin__user_checkbox} />
                        <p className={styles.admin__user_amount}>
                            Actual Payout
                        </p>
                        {/* <p className={styles.admin__user_amount}>
                            Starting Amount - Expected Payout
                        </p> */}
                    </li>
                    {data && data.length > 0 && data.map((x, i) => {
                        return (
                            <AdminMLMUser key={i} x={x} i={i} updateAllUsers={updateAllUsers} />
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}