import { Routes, Route, useNavigate, useParams, Navigate } from "react-router"
import { generateUrl, getParams, safeNavigate, truncateAddress, useQuery } from "../utils"

import NewVote from './../components/NewVote'
import Vote from './../components/Vote'
import config from "../config";
import Topics from "../components/Topics";
import { Link } from "react-router-dom";
import AllVotes from "./AllVotes";
import SomeVote from "./SomeVote";
import buyNft from "../contracts/BuyNFT";
import { useContext, useState } from "react";
import { PageContext } from "../context";

export default function Dao({someVote, setSomeVote, updateSomeVote, thisVoteNotif, setThisVoteNotif,setCurrentAccount, setThisVote, voteId, topic, thisVote, currentAccount, filteredVotes, setVotes, votes, search, role, setSureContainer, setSure, yourAvatar, setIsNewVote}) {

  document.title = 'Raiders • DAO'

  const navigate = useNavigate()

  const service = useContext(PageContext)

  return (
    <div className="votes container">
      <div className="votes__left">
        <Routes>
          <Route exact path="/:topic" element={<AllVotes voteId={voteId} filteredVotes={filteredVotes} currentAccount={currentAccount} />} />
          <Route exact path="/:topic/:voteId" element={<SomeVote someVote={someVote} setSomeVote={setSomeVote} updateSomeVote={updateSomeVote} voteId={voteId} navigate={navigate} topic={topic} thisVote={thisVote} filteredVotes={filteredVotes} setThisVote={setThisVote} service={service} currentAccount={currentAccount} setVotes={setVotes} search={search} votes={votes} role={role} setSureContainer={setSureContainer} setSure={setSure} />} />
          <Route exact path="/new" element={<NewVote updateVotes={service.updateVotes} currentAccount={currentAccount} setCurrentAccount={setCurrentAccount} votes={votes} yourAvatar={yourAvatar} setIsNewVote={setIsNewVote} />} />
          <Route path='*' element={<Navigate to='/dao/voting/all' replace/>}/>
        </Routes>
      </div>
      <div className="votes__right">
        <div className="votes__stats">
          <p className="votes__stat">
            Voting budget: {service.balanceVotes == null ? 0 : service.balanceVotes}
          </p>
          <p className="votes__stat">
            Remaining votes: {service.remainingVotes == null ? 0 : service.remainingVotes}
          </p>
        </div>
        <Topics votes={votes} navigate={navigate} search={search} topic={topic} />
      </div>
    </div>
  )
}