import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import api from "./fetch";

export function truncateAddress(address) {
    if (!address) {
        return "";
    }
    return `${address.substr(0, 5)}...${address.substr(
        address.length - 5,
        address.length
    )}`;
}

export function safeNavigate(navigate, url, search) {
    navigate([url, search].join(''))
}

export function isMobileDevice() {
    return 'ontouchstart' in window || 'onmsgesturechange' in window;
}

export function useQuery() {
    const { search } = useLocation();
  
    return useMemo(() => {
      const params = new URLSearchParams(search)
      const obj = { search }
      for (const key of params.keys()) {
        if (params.getAll(key).length > 1) {
          obj[key] = params.getAll(key)
        } else {
          obj[key] = params.get(key)
        }
      }
      console.log('obj', obj)
      return obj
    }, [search]);
  }
  

export function generateUrl(url, params) {
  console.log("Set params", params)
  for (let paramKey in params) {
    if (params[paramKey] != null) {
      url = url.replace(`:${paramKey}`, params[paramKey])
    } else {
      if (url.indexOf(`:${paramKey}/`) != null) {
        url = url.replace(`:${paramKey}/`, "")
      } else {
        url = url.replace(`:${paramKey}`, "")
      }
    }
  }
  return url
}

export function getParams(params) {
  const { topic, voteId } = params

  if (voteId != null && voteId.match(/[a-f0-9]{24}/)) {
    return { topic, voteId }
  }
  return { topic: voteId }
}

export function inIframe() {
  try {
      return window.self !== window.top;
  } catch (e) {
      return true;
  }
}

export function deleteCookie(name) {
  api.get("/auth/logout")
}

export function fetchRequest() {}