import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { truncateAddress } from "../utils";

import tradeUpImg from './../assets/img/trade-up.webp'

import village1 from './../assets/img/village1.png'
import character1 from './../assets/img/character1.png'

import boxImg from './../assets/img/boxInventory.png'

import buyNft, { uiAmount } from './../contracts/BuyNFT.js'
import api from "../fetch";
import InventoryImg from "../components/InventoryImg";
import InventoryBoxs from "../components/InventoryBoxs";
import OpenBoxs from "../components/OpenBoxs";
import gsap from "gsap";
import { PageContext } from "../context";

export default function Inventory () {

    document.title = 'Raiders • Inventory'

    const service = useContext(PageContext)

    const [viewDrop, setViewDrop] = useState([])
    const [itemsInventory, setItemsInventory] = useState([])


    async function Preload(currentAccount) {
        if (currentAccount != null) {
          const response = await api.get(`/user/inventory`)
          setItemsInventory(response.map(x => {
            return {
                contract: {
                    address: x.contract
                },
                tokenId: x.id,
                rawMetadata: {
                    tokenId: x.id,
                    name: x.metadata.name,
                    description: x.metadata.description,
                    image: x.metadata.image,
                    attributes: x.metadata.attributes
                },
                balance: x.balance
            }
          }))
        }
    }

    useEffect(() => {
        Preload(service.currentAccount)
    }, [service.currentAccount])

    const animOpenBoxs = useRef()
    const [isOpenBoxs, setIsOpenBoxs] = useState(false)
  
    useEffect(() => {
      animOpenBoxs.current = gsap.timeline({paused: true}).to(service.q('.open-modal'), {
        opacity: 1,
        display: 'flex',
        duration: 0.5
      }).to(service.q('.open-modal__container'), {
        scale: 1,
        duration: 1
      })
    }, [])
  
    useEffect(() => {
      if (isOpenBoxs) {
        animOpenBoxs.current.play().duration(1.5)
      } else {
        animOpenBoxs.current.reverse().duration(0.75)
      }
    }, [isOpenBoxs])

    const [choose, setChoose] = useState('all')
    const [showStats, setShowStats] = useState(false)

    if (service.role <= -1) return <Navigate to='/dao/voting' replace/>
    return (
        <div className="inventory">
            <div className="inventory__container container">
                <div className="inventory__top">
                    <div className="inventory__titles">
                        <Link to="/dao/voting" className="title inventory__title">
                            <div className="title__arrow" />
                            <h2 className="title__descr">
                                My Inventory
                            </h2>
                        </Link>
                        <div className="inventory__settings inventory__settings2">
                            <p className="inventroy__budget">
                                Voting budget: {service.balanceVotes}
                            </p>
                            <div className="inventory__show inventory__show2">
                                <p className="inventory__show-title">
                                    Show info
                                </p>
                                <label className="inventory__show-switch">
                                    <input type="checkbox" className="inventory__show-input" checked={showStats} onChange={(e) => {
                                        setShowStats(e.target.checked)
                                    } } />
                                    <span className="inventory__show-slider" />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="drops__last">
                        <a href="https://constitution.rgw.zone/inventory" target="_blank" className="drops__btn drops__btn-history">
                            read.me
                        </a>
                    </div>

                    <div className="inventory__settings inventory__settings1">
                        <p className="inventroy__budget">
                            Voting budget: {service.balanceVotes}
                        </p>
                        <div className="inventory__show inventory__show2">
                            <p className="inventory__show-title">
                                Show info
                            </p>
                            <label className="inventory__show-switch">
                                <input type="checkbox" className="inventory__show-input" checked={showStats} onChange={(e) => {
                                    setShowStats(e.target.checked)
                                } } />
                                <span className="inventory__show-slider" />
                            </label>
                        </div>
                    </div>
                    <div className="inventory__info">
                        <div className="inventory__show inventory__show1">
                            <p className="inventory__show-title">
                                Show info
                            </p>
                            <label className="inventory__show-switch">
                                <input type="checkbox" className="inventory__show-input" checked={showStats} onChange={(e) => {
                                    setShowStats(e.target.checked)
                                } } />
                                <span className="inventory__show-slider" />
                            </label>
                        </div>
                        <div className="inventory__player">
                            <p className="inventory__name">
                                {service.name}
                            </p>
                            <div className="inventory__avatar" style={{
                                backgroundImage: `${service.yourAvatar}`
                            }}/>
                            <p className="inventory__address">
                                {truncateAddress(service.currentAccount)}
                            </p>
                        </div>
                        <p className="inventory__rank" style={{
                            color: service.displayRole === 'Superadmin' ? '#ffff00' : (service.displayRole === 'Duke' ? '#f40101' : (service.displayRole === 'Earl' ? '#00ffff' : (service.displayRole === 'Baron' ? '#00ff00' : '#efefef')))
                        }}>
                            {service.displayRole}
                        </p>
                        <div className="inventory__level">
                            <img className="inventory__icon" src={tradeUpImg} alt=''/>
                            <p className="inventory__level-descr">
                                Level:
                            </p>
                            <p className="inventory__number">NA</p>
                        </div>
                    </div>
                </div>
                <div className="inventory__chooses">
                    <button className={`inventory__choose ${choose === 'all' ? 'inventory__choose--active' : ''}`} onClick={() => {
                        setChoose('all')
                    }}>
                        All
                    </button>
                    <button className={`inventory__choose ${choose === 'boxs' ? 'inventory__choose--active' : ''}`} onClick={() => {
                        setChoose('boxs')
                    }}>
                        Boxs
                    </button>
                    <button className={`inventory__choose ${choose === 'villages' ? 'inventory__choose--active' : ''}`} onClick={() => {
                        setChoose('villages')
                    }}>
                        Villages
                    </button>
                    <button className={`inventory__choose ${choose === 'characters' ? 'inventory__choose--active' : ''}`} onClick={() => {
                        setChoose('characters')
                    }}>
                        Characters
                    </button>
                </div>
                <div className="inventory__bottom">
                    <div className="inventory__grid">
                        {itemsInventory.length > 0 ? itemsInventory.map((x, i) => {

                            const isBox = x.contract.address.toLowerCase() === buyNft.contractAddresses.RaidersNFTBox.toLowerCase()

                            const items = []

                            for (let i = 0; i < x.balance; i++) {
                                items.push('')
                            }

                            if (choose === 'all') {
                                return (
                                    isBox ? (
                                        <React.Fragment key={i}>
                                            {items.length > 0 ? items.map((k, j) => {
                                                return (
                                                    <InventoryBoxs Preload={Preload} key={i+j} setIsOpenBoxs={setIsOpenBoxs} viewDrop={viewDrop} setViewDrop={setViewDrop} showStats={showStats} data={x}/>
                                                )
                                            }) : null}
                                        </React.Fragment>
                                    ) : (
                                        <InventoryImg key={i} showStats={showStats} rawMetadata={x.rawMetadata} address={x.contract.address} />
                                    )
                                )
                            } else if (choose === 'boxs') {
                                if (x.contract.address.toLowerCase() === buyNft.contractAddresses.RaidersNFTBox.toLowerCase()) {
                                    return (
                                        <React.Fragment key={i}>
                                            {items.length > 0 ? items.map((k, j) => {
                                                return (
                                                    <InventoryBoxs Preload={Preload} key={i+j} setIsOpenBoxs={setIsOpenBoxs} viewDrop={viewDrop} setViewDrop={setViewDrop} showStats={showStats} data={x}/>
                                                )
                                            }) : null}
                                        </React.Fragment>
                                    )
                                }
                                return ''
                            } else if (choose === 'villages') {
                                if (x.contract.address.toLowerCase() === buyNft.contractAddresses.RaidersNFTLand.toLowerCase()) {
                                    return (
                                        <InventoryImg key={i} showStats={showStats} rawMetadata={x.rawMetadata} address={x.contract.address} />
                                    )
                                }
                                return ''
                            } else if (choose === 'characters') {
                                if (x.contract.address.toLowerCase() === buyNft.contractAddresses.RaidersNFTCharacter.toLowerCase()) {
                                    return (
                                        <InventoryImg key={i} showStats={showStats} rawMetadata={x.rawMetadata} address={x.contract.address} />
                                    )
                                }
                                return ''
                            } else {
                                return ''
                            }
                        }) : null}
                    </div>
                </div>
            </div>
            <OpenBoxs Preload={Preload} viewDrop={viewDrop} setIsOpenBoxs={setIsOpenBoxs} />
        </div>
    )
}