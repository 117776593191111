import Web3 from "web3/dist/web3.min.js"

import { gsap } from "gsap";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { safeNavigate, isMobileDevice, useQuery, generateUrl, getParams, inIframe, deleteCookie } from "./utils";

import 'swiper/css';
import 'swiper/css/navigation';

import { Navigate, Route, Routes, useNavigate } from "react-router-dom"

import config from "./config"

import './css/normalize.css';
import './css/style.css';

import { PageContext } from './context'

import UserContext from './contexts/UserContext'

import { useParams } from "react-router-dom";

import BackgroundSwiper from "./components/BackgroundSwiper";
import Menu from "./components/Menu";
import SureContainer from "./components/SureContainer";
import DropsModal from "./components/DropsModal";

import buyNft from "./contracts/BuyNFT";
import api from "./fetch";
import Admin from "./pages/Admin";
import Drops from "./pages/Drops";
import Rewards from "./pages/Rewards/Rewards";
import SalesHistory from "./pages/SalesHistory";
import Dao from "./pages/Dao";
import Inventory from "./pages/Inventory";
import Navigation from "./components/Navigation";

import defaultAvatar from './assets/img/defaultAvatar.png'
import ModalEmail from "./components/ModalEmail";
import Loader from "./components/Loader";

function App() {

  const params = useParams()

  const navigate = useNavigate()

  let [_, topic, voteId] = params['*'].split('/')

  if (voteId != null && voteId.length == 0) 
    voteId = null
  if (topic == 'all') 
    topic = null

  const { menuOpen, search, ref, menu } = useQuery()

  useEffect(() => {
    if (ref != null) {
      localStorage.setItem('ref', ref)
      navigate('/dao/drops')
    }
    if (menu != null) {
      setMenuOpen(true)
    }
  }, [])

  const el = useRef()
  const q = gsap.utils.selector(el)
  const [user, setUser] = useState(null)
  const [currentAccount, setCurrentAccount] = useState(null)
  const [currentBalance, setCurrentBalance] = useState(-1)
  const connectAnim = useRef()
  const [role, setRole] = useState(-1)
  const [userId, setUserId] = useState('')
  const [displayRole, setDisplayRole] = useState("Guest")
  const [balanceVotes, setBalanceVotes] = useState(0)
  const [remainingVotes, setRemainingVotes] = useState(0)
  const [name, setName] = useState("Guest")
  const [agreement, setAgreement] = useState(null)
  const [verified, setVerified] = useState(false)
  const [email, setEmail] = useState("")
  const [isMenuOpen, setMenuOpen] = useState(menuOpen != null)
  const animMenu = useRef()
  const [votes, setVotes] = useState([])
  const [sure, setSure] = useState({id: 0, vote: true})
  const sureAnim = useRef()
  const [sureContainer, setSureContainer] = useState(false)
  const [yourAvatar, setYourAvatar] = useState(`url(${defaultAvatar})`)

  const [chainId, setChainId] = useState(0)

  const web3 = useRef(null)
  const userRef = useRef(null)

  const [load, setLoad] = useState(false)

  async function updateVotes0() {
    setVotes(await service.fetchVotes())
  }

  const service = {
    web3: web3.current != null ? web3.current : null,
    currentAccount, setCurrentAccount,
    currentBalance,
    yourAvatar, setYourAvatar,
    role, setRole,
    load, setLoad,
    userId, setUserId,
    name, setName,
    agreement, setAgreement,
    email, setEmail,
    displayRole, setDisplayRole,
    votes, setVotes,
    verified, setVerified,
    q, balanceVotes, setBalanceVotes,
    remainingVotes, setRemainingVotes,

    chainId,

    updateVotes: () => {
      return updateVotes0()
    },

    fetchVotes: async () => {
      const fetchedVotes = await api.get(`/voting?from=${service.currentAccount}`, service.authCallback)
      return fetchedVotes
    },

    signMessage: async (message) => {
      if (service.currentAccount == null) {
        throw new Error('Account is null')
      }

      return await service.web3.eth.personal.sign(message, service.currentAccount, '')
    },

    authCallback: () => {
      return onCurrentAccountChange()
    },

    fetchRequest: (url, body) => {
      return api.post(url.substring(config.apiEndpoint.length), body, service.authCallback)
    },
    
    fetchRequest2: (url, body, noSign, sign) => {
      return new Promise((resolve, reject) => {
          fetch(url, {
              method: "POST",
              body: JSON.stringify(Object.assign(body, {
                signature: sign
              })),
              headers: {
                'Content-Type': 'application/json'
              },
              credentials: "include"
          }).then(res => res.json()).then(async res => {

              console.log({ resres: res })

              if (res.statusCode === 401) {
                  const result = await service.fetchRequest2(url, body, console.log, await service.signMessage(res.message))
                  console.log('connect', result)
                  resolve(result)
              } else {
                  console.log('res', res)
                  resolve(res)
              }
          }).catch(reject)
      })
    },

    fetchUser: async () => {
      if (service.currentAccount != null) {
        const data = await api.get(`/user?from=${service.currentAccount}`, onCurrentAccountChange)
        userRef.current = data
        return data
      }
      return {}
    },

    updateUser: async () => {
        if (service.currentAccount != null) {
          setUser(userRef.current)
          setYourAvatar(userRef.current.avatar != null && userRef.current.avatar !== 'url(undefined)' ? userRef.current.avatar : `url(${defaultAvatar})`)
          setAgreement(userRef.current.agreement != null ? userRef.current.agreement : null)
          if (userRef.current.name == null) {
            setName('Name')
            setEmail('')
          } else {
            setName(userRef.current.name)
            setEmail(userRef.current.email)
          }

          setUserId(userRef.current.id)
          
          setRole(userRef.current.role)
          setDisplayRole(userRef.current.displayRole)
        }
    },

    checkTotalPlayers: async () => {
      const totalPlayers = await buyNft.daoTotalMembers()
      return totalPlayers
    },

    checkAllowance: async () => {
      const allowance = await buyNft.allowance(buyNft.tokenAddress)
      return allowance
    },

    checkBalance: async () => {
      const allowance = await buyNft.balanceOf(buyNft.tokenAddress)
      return allowance
    },

    updateVotesBalance: async () => {
      if (currentAccount != null) {
        Promise.all([buyNft.daoGetVotingBudget(), buyNft.daoGetUsedWeightedBudget()]).then(([balance, remainingVotes]) => {
          setBalanceVotes(balance)
          setRemainingVotes(Math.floor(Math.sqrt(balance)) - remainingVotes)
        })
      }
    }
  }

  useEffect(() => {
    async function func () {
      setVotes(await service.fetchVotes())
    }
    func()
  }, [])

  useEffect(() => {
    sureAnim.current = gsap.timeline({paused: true}).to(q('.sure'), {
      display: 'flex',
      opacity: 1,
      duration: 1,
    })
  }, [])

  useEffect(() => {
    if (sureContainer) {
      sureAnim.current.play()
    }
    else {
      sureAnim.current.reverse()
    }
  }, [sureContainer])

  function findVote(id) {
    const filter = votes.filter(v => v.id === id)
    if (filter.length > 0)
      return filter[0]
    return null
  }

  const idVotes = findVote(voteId)

  const [thisVote, setThisVote] = useState(idVotes == null ? {
    id: 1,
    title: `Vote #1`,
    descr: 'Should we invite @raiderbeast into the clan?',
    address: null,
    date: '01/08/2022',
    tag: 'dev',
    name: 'Name',
    shortTitle: 'Vote #1',
    endDate: new Date().getTime(),
    canVote: false,
    members: {
      voted: 12,
      all: 24,
      yes: 10,
      no: 2,
    },
    notify: false,
    status: true,
    clan: null,
    topic: 'gameplay',
    type: 'general'
  } : idVotes)

  const localTopic = window.localStorage.getItem('topic') == null ? "all" : window.localStorage.getItem('topic')

  const filteredVotes = topic == null ? votes : votes.filter(vote => vote.topic === localTopic || localTopic === "all") 

  useEffect(() => {
    const filteredVotes = topic == null ? votes : votes.filter(vote => vote.topic === localTopic || localTopic === "all") 
    const fVote = voteId == null && filteredVotes.length > 0 ? filteredVotes[0] : findVote(voteId)
    console.log('myVotes', fVote)
    console.log('filterVotes', filteredVotes)
    if (fVote != null && voteId != null) {
      if (menuOpen == null && voteId == null)
        safeNavigate(navigate, generateUrl("/dao/voting/:topic/:voteId", {
          topic, voteId: fVote.id
        }), search)
      setThisVote(fVote)
    }
  }, [votes, voteId])

  const onCurrentAccountChange = async function (newCurrentAccount) {

    console.log(new Error(), 'error')

    const { ethereum } = window
    if (!ethereum)
      return

    console.log({ newCurrentAccount })

    if (newCurrentAccount != null) {
      try {
        const myChainId = '0x' + (137).toString(16)
        let chainId = await ethereum.request({ method: 'eth_chainId' })

        setChainId(chainId)

        if (chainId != myChainId) {

          chainId = await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: myChainId }],
          }).catch(async (e) => {

            if (e.code == 4001)
              throw new Error('unsigned')

            const chainId = await ethereum.request({ 
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: myChainId,
                  chainName: 'Polygon Mainnet',
                  rpcUrls: ['https://rpc.ankr.com/polygon'],
                  blockExplorerUrls: ["https://polygonscan.com/"],
                  nativeCurrency: {
                    name: 'MATIC',
                    symbol: 'MATIC',
                    decimals: 18
                  }
                }
              ]
            })
          }).then(async () =>
            await ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: myChainId }],
            })
          ).then(() => myChainId).catch(() => {
            return '-0x01'
          })
          

        }
        if (chainId != myChainId) {
          setCurrentAccount(null)
          return
        }
        console.log('register response', await service.fetchRequest2(`${config.apiEndpoint}/auth/register?from=${newCurrentAccount}`, {
          ref: localStorage.getItem('ref')
        }))
        setCurrentBalance(service.web3.utils.fromWei(await service.web3.eth.getBalance(newCurrentAccount), "ether"))
        if (window.localStorage.getItem('account') == null) {
            window.localStorage.setItem('account', newCurrentAccount)
            connectAnim.current.play()
        } else {
          connectAnim.current.play().progress(0.9)
        }
      } catch (ex) {
        //alert("Account error")
        console.error(ex)
        setCurrentAccount(null)
      }
    } else {
      connectAnim.current.reverse()
    }
    setVotes(await service.fetchVotes())
  }

  const [someVote, setSomeVote] = useState([])

  async function updateSomeVote(currentAccount) {
    if (voteId != null) {
      service.setLoad(true)
      await api.get(`/voting/${voteId}/info`, service.authCallback).then(res => {
        if (!res.error || res != null) {
          setSomeVote(res)
        }
        service.setLoad(false)
        console.log('someVote info:', res)
      })
    }
  }

  const accountsChangedHandler = async (accounts) => {

    const { ethereum } = window
    if (!ethereum)
      return

    console.log('accountsChangedHandler', new Error())

    const registerResponse = await api.get(`/auth/register?from=${accounts[0]}`)
    console.log({registerResponse})
    if (accounts.length !== 0 && registerResponse.status) {
      if (web3.current == null) {
        web3.current = new Web3(window.ethereum)
      }
      buyNft.setup(web3.current)
      setCurrentAccount(accounts[0])
      console.log('accounts[0]', accounts[0])
      await updateSomeVote(accounts[0])
    } else {
      window.localStorage.removeItem('account')
      alert("Please sign in")
      setCurrentAccount(null);
    }
  }

  const chainChangedHandler = async (chainId) => {
    setChainId(chainId)
  }

  useEffect(() => {
    connectAnim.current = gsap.timeline({paused: true }).to(q('.menu-connect__icon-login'), {
      opacity: 0,
      display: 'none',
      duration: 0.3,
    }).to('.menu-connect__icon-loading', {
      opacity: 1,
      display: 'block',
      duration: 0.2,
    }).to('.menu-connect__icon-loading', {
      duration: 2.5,
      rotation: 1080,
    }).to('.menu-connect__icon-loading', {
      scale: 0,
      duration: 0.5,
      opacity: 0,
      display: 'none',
    }).to('.menu-connect__icon-connected', {
      display: 'block',
      opacity: 1,
      duration: 0.5,
      scale: 1,
    }).to('.menu-connect__status-disconnect', {
      opacity: 0,
      display: 'none',
    }).fromTo('.menu-connect__status-connect', {
      x: -50,
    }, {
      display: 'inline',
      opacity: 1,
      x: 0,
      duration: 0.5,
    }).to('.menu-right__container', {
      opacity: 0,
      display: 'none',
      duration: 0.5,
    }).to('.menu-right__content', {
      opacity: 1,
      display: 'block',
      duration: 0.5
    }).to('.menu-connect__settings', {
      visibility: 'visible',
      duration: 0.5
    }).to('.menu-connect__icon-connected', {
      opacity: 0,
      display: 'none',
      duration: 0.5,
      delay: 1
    }).to('.menu-connect__icon-some', {
      filter: 'none',
      duration: 0.5
    })
  }, [])

  useEffect(() => {
    const { ethereum } = window
    if (!ethereum)
      return
    ethereum.on("chainChanged", chainChangedHandler);
    updateSomeVote()
  }, [])


  const connectWalletHandler = async () => {

    const { ethereum } = window

    if (!ethereum) {
      if (isMobileDevice()) {
        window.location.href = `https://metamask.app.link/dapp/${window.location.href}`
      } else {
        //alert("Please install Metamask!");
      }
      return
    }

    try {
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });

      console.log('eth_requestAccounts', accounts)

      console.log("Found an account! Address: ", accounts[0]);
      if (web3.current == null) {
        web3.current = new Web3(window.ethereum)
      }
      buyNft.setup(web3.current)
      setCurrentAccount(accounts[0])
      if (!window._accountsChanged) {
        //alert('handler')
        window._accountsChanged = true
        ethereum.on('accountsChanged', accountsChangedHandler)
      }
    } catch (err) {
      console.log(err)
      //alert("ERROR")
    }
  }

  const checkWalletIsConnected = async () => {
    
    const { ethereum } = window

    if (!ethereum) {
      //alert("Make sure you have Metamask installed!");
      return;
    } else {
      if (window.localStorage.getItem('account') != null) {
        const accounts = await ethereum.request({ method: 'eth_accounts' });
        console.log('eth_accounts', accounts)
        if (accounts.length > 0) {
          const registerResponse = await api.get(`/auth/register?from=${accounts[0]}`)
          console.log({registerResponse})
          if (registerResponse.status)
            connectWalletHandler()
        }
        else {
          window.localStorage.removeItem('account')
          deleteCookie('access_token')
        }
      } else {
        deleteCookie('access_token')
      }
    }
  }

  useEffect(() => {
    checkWalletIsConnected();
  }, [])

  useEffect(() => {
    animMenu.current = gsap.timeline().to(q('.menu'), {
      x: 0,
      opacity: 1,
      display: 'block',
      duration: 1,
    })
  }, [])

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden"
      if (menuOpen)
        animMenu.current.progress(1)
      else
        animMenu.current.play()
    } else {
      document.body.style = null
      animMenu.current.reverse()
    }
  }, [isMenuOpen])

  const animTitleHidden = useRef()

  useEffect(() => {
    animTitleHidden.current = gsap.timeline({paused: true}).to(q('.title__absolute'), {
      opacity: 0,
      display: 'none',
      duration: 0.5
    })
  }, [])

  const [isReferralList, setIsReferralList] = useState([{name: 'Loading...', address: 'Loading...', invited: 0, income: 0}])
  const [totalInvitation, setTotalInvitation] = useState(0)

  useEffect( () => {
    
    async function run() {

      console.log('new acc fetch', currentAccount)

      await onCurrentAccountChange(currentAccount)
      if (currentAccount != null) {

        console.log(currentAccount, 'fetch')

        const data = await service.fetchUser()
        setUser(data)
        setYourAvatar(data.avatar != null ? data.avatar : `url(${defaultAvatar})`)
        setAgreement(data.agreement != null ? data.agreement : null)
        if (data.name == null) {
          setName('noname')
          setEmail('')
          setVerified(false)
        } else {
          setVerified(data.verified != null ? data.verified : false)
          setName(data.name)
          setEmail(data.email)
        }

        setIsReferralList(data.referrals)

        setTotalInvitation(data.totalInvitation)
  
        setUserId(data.id)
        setRole(data.role)
        setDisplayRole(data.displayRole)

        await Promise.all([buyNft.daoGetVotingBudget(), buyNft.daoGetUsedWeightedBudget()]).then(([balance, remainingVotes]) => {
          setBalanceVotes(balance)
          setRemainingVotes(Math.floor(Math.sqrt(balance)) - remainingVotes)
        })
      }
    }
    run()
  }, [currentAccount])

  function logout() {
    //alert("alert logout 627")
    setCurrentAccount(null)
    window.localStorage.removeItem('account')
    setUser(null)
    deleteCookie('access_token')
    setRole(-1)
    setDisplayRole("Guest")
    setName("Guest")
    setAgreement(null)
    setVerified(false)
    setEmail('')
    setYourAvatar(`url(${defaultAvatar})`)
  }

  const animModalDrops = useRef()
  const [isModalDrops, setIsModalDrops] = useState(false)

  useLayoutEffect(() => {
    animModalDrops.current = gsap.timeline({paused: true}).fromTo(q('.drops__modal'), {
      opacity: 0,
      display: 'none',
      duration: 0.5
    },{
      opacity: 1,
      display: 'flex',
      duration: 0.5
    })
  }, [])

  const [isDropList, setIsDropList] = useState([
    {boxId: 0, name: "Raiders box 1", price: [20, 50, 100], quantity: [0,0,0], status: ['none', 'none', 'none'], image: null, description: 'Loading...', orderId: [null, null, null]}, 
    {boxId: 1, name: "Raiders box 2", price: [20, 50, 100], quantity: [0,0,0], status: ['none', 'none', 'none'], image: null, description: 'Loading...', orderId: [null, null, null]}, 
    {boxId: 2, name: "Raiders box 3", price: [20, 50, 100], quantity: [0,0,0], status: ['none', 'none', 'none'], image: null, description: 'Loading...', orderId: [null, null, null]}
  ])

  const [isDropListAdmin, setIsDropListAdmin] = useState([
    {boxId: 0, name: "Raiders box 1", price: [20, 50, 100], quantity: [0,0,0], status: ['none', 'none', 'none'], image: null, description: 'Loading...', orderId: [null, null, null], villages: [0, 0, 0], characters: [0, 0, 0]}, 
    {boxId: 1, name: "Raiders box 2", price: [20, 50, 100], quantity: [0,0,0], status: ['none', 'none', 'none'], image: null, description: 'Loading...', orderId: [null, null, null], villages: [0, 0, 0], characters: [0, 0, 0]}, 
    {boxId: 2, name: "Raiders box 3", price: [20, 50, 100], quantity: [0,0,0], status: ['none', 'none', 'none'], image: null, description: 'Loading...', orderId: [null, null, null], villages: [0, 0, 0], characters: [0, 0, 0]}
  ])

  const [boxTopList, setBoxTopList] = useState({status: false, nextSaleDate: Date.now(), totalRaised: Number('0'), name: 'Loading...', minNeed: 0, maxNeedTotal: 10, finishDate: Date.now(), currency: 'USDT', createdDate: Date.now(), tooltip: 'Increase your reward % by inviting new members. See the Constitution for details.'})

  const [dateForModal, setDateForModal] = useState({ admin: false, index: 0, loading: false })

  async function BoxsLoading() {
    await api.get(`/box`).then(res => {
        console.log('reload drop', res)
        if (!res.error && res.length !== 0) {
            setIsDropList(res)
        }
    })
  }

  async function BoxsLoadingAdmin() {
    await api.get(`/box?admin`).then(res => {
        console.log('reload drop admin', res)
        if (!res.error && res.length !== 0) {
            setIsDropListAdmin(res)
        }
    })
  }

  return (
    <PageContext.Provider value={service}>
      <UserContext.Provider value={{user, setUser, load, setLoad}}>
        {load && <Loader/>}
        <div className="root" ref={el}>
          <BackgroundSwiper>
            <div className="root__bg" style={{ backgroundImage: 'linear-gradient(to bottom, rgba(0,27,77,0.9), rgba(20,20,28,0.9)), url(/assets/bg1.jpg)' }} />
            <div className="root__bg" style={{ backgroundImage: 'linear-gradient(to bottom, rgba(0,27,77,0.9), rgba(20,20,28,0.9)), url(/assets/bg2.png)' }} />
            <div className="root__bg" style={{ backgroundImage: 'linear-gradient(to bottom, rgba(0,27,77,0.9), rgba(20,20,28,0.9)), url(/assets/bg3.png)' }} />
          </BackgroundSwiper>
          <Menu role={role} q={q} setMenuOpen={setMenuOpen} onHandleConnect={connectWalletHandler} onLogout={logout} />
          <Navigation currentAccount={currentAccount} role={role} setMenuOpen={setMenuOpen}/>
          <div className="main">
            <Routes>
              <Route path="/inventory" element={<Inventory/>} />
              <Route path="/voting/*" element={<Dao someVote={someVote} updateSomeVote={updateSomeVote} setSomeVote={setSomeVote} yourAvatar={yourAvatar} setThisVote={setThisVote} setCurrentAccount={setCurrentAccount} setSure={setSure} setSureContainer={setSureContainer} search={search} role={role} votes={votes} setVotes={setVotes} filteredVotes={filteredVotes} voteId={voteId} topic={topic} thisVote={thisVote} service={service} currentAccount={currentAccount} />} />
              <Route path="/drops" element={<Drops boxTopList={boxTopList} setBoxTopList={setBoxTopList} dateForModal={dateForModal} setDateForModal={setDateForModal} isDropList={isDropList} BoxsLoading={BoxsLoading} service={service} isModalDrops={isModalDrops} setIsModalDrops={setIsModalDrops} displayRole={displayRole} name={name} yourAvatar={yourAvatar} currentAccount={currentAccount} />} />
              <Route path="/admin/*" element={<Admin owner={name} boxTopList={boxTopList} setBoxTopList={setBoxTopList} setDateForModal={setDateForModal} isDropList={isDropListAdmin} BoxsLoading={BoxsLoadingAdmin} service={service} setIsModalDrops={setIsModalDrops} q={q} currentAccount={currentAccount} name={name} role={role} yourAvatar={yourAvatar} />} />
              <Route path="/referrals" element={<Rewards isReferralList={isReferralList} totalInvitation={totalInvitation} />} />
              <Route path="/drops/history" element={<SalesHistory/>} />
              <Route path='*' element={<Navigate to='/dao/voting' replace/>}/>
            </Routes>
          </div>
          <DropsModal isModalDrops={isModalDrops} animModalDrops={animModalDrops} isDropList={isDropList} isDropListAdmin={isDropListAdmin} boxTopList={boxTopList} BoxsLoading={BoxsLoading} BoxsLoadingAdmin={BoxsLoadingAdmin} setIsModalDrops={setIsModalDrops} currentAccount={currentAccount} setDateForModal={setDateForModal} dateForModal={dateForModal} service={service} finishDate={boxTopList.finishDate} />
          <SureContainer updateSomeVote={updateSomeVote} setSureContainer={setSureContainer} sure={sure} thisVote={thisVote} setVotes={setVotes}/>
          {new URLSearchParams(document.location.search).get('email_success') != null && <ModalEmail />}
        </div>
      </UserContext.Provider>
    </PageContext.Provider>
  );
}

export default App;