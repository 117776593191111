import { gsap } from "gsap"
import { useEffect, useRef, useState } from "react"

export default function ModalEmail() {

    const emailModalRef = useRef(null)

    const [emailModal, setEmailModal] = useState(new URLSearchParams(document.location.search).get('email_success') === 'true' ? true : false)
    const emailModalAnim = useRef()

    useEffect(() => {
        emailModalAnim.current = gsap.timeline({paused: true}).to(emailModalRef.current, {
            opacity: 0,
            display: 'none',
            duration: 0.5
        })
    }, [emailModalRef])

    return (
        <div ref={emailModalRef} className="email" onClick={() => {
            emailModalAnim.current.play()
        }}>
            <div className="email__container" onClick={(e) => {
            }}>
                <h3 className="email__title">
                    {emailModal ? (
                        "Thank you! You have successfully verified your account."
                    ) : (
                        "Something went wrong or the link has expired. Please try again or talk to our support team."
                    )}
                </h3>
                <button className="email__cancel" onClick={(e) => {
                    emailModalAnim.current.play()
                }}>
                    {emailModal ? 'Super!' : 'Cancel' }
                </button>
            </div>
        </div>
    )
}