import { truncateAddress } from "../utils";

import { useContext, useEffect, useRef, useState } from "react";

import gsap from "gsap";

import { Scrollbar, Navigation, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";

import tradeUpImg from './../assets/img/trade-up.webp'
import boxImg from './../assets/img/box.jpg'
import { Link } from "react-router-dom";
import { PageContext } from "../context";

export default function SalesHistory() {

    const service = useContext(PageContext)

    return (
        <div className="sales">
            <div className="sales__container container">
                <div className="sales__top">
                    <Link to='/dao/drops' className="sales__back" >
                        <div className="sales__back-arrow" />
                        <h2 className="sales__title">
                            Sales History
                        </h2>
                    </Link>
                    <div className="sales__info">
                        <div className="sales__player">
                            <p className="sales__name">
                                {service.name}
                            </p>
                            <div className="sales__avatar" style={{
                                backgroundImage: `${service.yourAvatar}`
                            }}/>
                            <p className="sales__address">
                                {truncateAddress(service.currentAccount)}
                            </p>
                        </div>
                        <p className="sales__rank" style={{
                            color: service.displayRole === 'Superadmin' ? '#ffff00' : (service.displayRole === 'Duke' ? '#f40101' : (service.displayRole === 'Earl' ? '#00ffff' : (service.displayRole === 'Baron' ? '#00ff00' : '#efefef')))
                        }}>
                            {service.displayRole}
                        </p>
                        <div className="sales__level">
                            <img className="sales__icon" src={tradeUpImg} alt=''/>
                            <p className="sales__descr">
                                Level:
                            </p>
                            <p className="sales__number">10</p>
                        </div>
                    </div>
                </div>
                <Swiper
                    scrollbar={{
                        draggable: true,
                        hide: false,
                        snapOnRelease: true,
                        el: '.sales__scrollbar'
                    }}
                    autoHeight={true}
                    direction={"vertical"}
                    slidesPerView={'auto'}
                    spaceBetween={25}
                    mousewheel={true}
                    modules={[Scrollbar, Mousewheel]}
                    className="sales__swiper"
                >
                    <div className="sales__scrollbar"/>
                    {[1, 2, 3, 4, 5, 6, 7, 8].map((x, i) => {
                        return (
                            <SwiperSlide key={'sales' + i} className="sales__item">
                                <div className="sales__left">
                                    <img src={boxImg} alt="" className="sales__img" />
                                </div>
                                <div className="sales__right">
                                    <div className="sales__block">
                                        <p className="sales__name">
                                            Raider's box (#{i+1})
                                        </p>
                                        <p className="sales__descr">
                                            Created: <span className="sales__strong">18.01.2022 12:00</span>
                                        </p>
                                    </div>
                                    <div className="sales__block">
                                        <p className="sales__descr">
                                            Currency: <span className="sales__strong">ETH</span>
                                        </p>
                                        <p className="sales__descr">
                                            Price category: <span className="sales__strong">0.001 - 0.0055 ETH</span>
                                        </p>
                                    </div>
                                    <div className="sales__block">
                                        <p className="sales__descr">
                                            Boxes saled:
                                        </p>
                                        <p className="sales__strong">
                                            10 epic, 10 rare, 10 common
                                        </p>
                                    </div>
                                    <div className="sales__block">
                                        <p className="sales__descr">
                                            Privileged sells: <span className="sales__strong">30</span>
                                        </p>
                                        <p className="sales__descr">
                                            Total bonus payed: <span className="sales__strong">2 ETH</span>
                                        </p>
                                    </div>
                                    <div className="sales__block">
                                        <p className="sales__descr">
                                            Participation:
                                        </p>
                                        <p className="sales__strong">
                                            Not participated
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
        </div>
    )
}