
import { Link, useLocation } from "react-router-dom";
import Logo from "./../assets/img/logo.svg";

export default function Navigation ({role, currentAccount, setMenuOpen}) {

    const location = useLocation().pathname

    
    return (
        <header className="header">
            <div className="header__container">
                <Link to="/" className="header__logo">
                    <img src={Logo} className="header__svg"/>
                </Link>
                <nav className="header__nav">
                    <Link to='/dao/drops' className={`header__link ${location === '/dao/drops' ? 'header__link--active' : ''}`}>
                        Buy NFT Boxs
                    </Link>
                    <Link to='/dao/voting' className={`header__link ${location.indexOf('/dao/voting') !== -1 ? 'header__link--active' : ''}`} style={{
                        marginRight: role >= 0 ? null : '0px'
                    
                        /*переименовал General Voting в DAO*/ 
                    }}>
                        DAO 
                    </Link>
                    <Link to='/dao/referrals' className={`header__link ${location === '/dao/referrals' ? 'header__link--active' : ''}`} style={{
                        display: role >= 0 ? 'block' : 'none'
                    }}>
                        Referral &amp;&nbsp;Rewards
                    </Link>
                    <Link to='/dao/inventory' className={`header__link ${location === '/dao/inventory' ? 'header__link--active' : ''}`} style={{
                        display: role >= 0 ? 'block' : 'none'
                    }}>
                        My inventory
                    </Link>
                    <Link to='/dao/admin' className={`header__link ${location === '/dao/admin' ? 'header__link--active' : ''}`} style={{
                        display: role >= 9 ? 'block' : 'none'
                    }}>
                        Admin panel
                    </Link>
                </nav>
                <button className="header__menu" onClick={() => {
                    setMenuOpen(true)
                }}>
                    <span className="header__menu-line"/>
                    <span className="header__menu-line"/>
                    <span className="header__menu-line"/>
                </button>
            </div>
        </header>
    )
}