import gsap from "gsap" 
import { useContext, useEffect, useRef, useState } from "react"
import { PageContext } from "../context"
import GenCard from "./GenCard"

export default function OpenBoxs({Preload, viewDrop, setIsOpenBoxs}) {

    const [images, setImages] = useState([])
    const [openAll, setOpenAll] = useState(false)

    const service = useContext(PageContext)

    useEffect(() => {
        setImages(viewDrop)
    }, [viewDrop])

    return (
        <div className="modal open-modal">
            <div className="open-modal__container">
                <ul className="open-modal__cards">
                    {images.map((x, i) => {
                        return (
                            <GenCard key={i} i={i} openAll={openAll} data={x} />
                        )
                    })}
                </ul>
            </div>
            <button className="open-modal__collect" onClick={async () => {
                    if (openAll) {
                        setIsOpenBoxs(false)
                        setTimeout(() => {
                            setOpenAll(false)
                        }, 750);
                    } else {
                        setOpenAll(true)
                    }
                    await Preload(service.currentAccount)
                }}>
                    {openAll ? 'Close' : 'Collect All'}
            </button>
        </div>
    )
}