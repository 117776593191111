import { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { SwiperSlide } from 'swiper/react'
import { truncateAddress, generateUrl, safeNavigate } from "../utils"

import gameplayImg from './../assets/img/gameplay.webp'
import punishmentsImg from './../assets/img/punishments.webp'
import websiteImg from './../assets/img/website.webp'
import flagImg from './../assets/img/flag.webp'
import starImg from './../assets/img/star.webp'
import tradeUpImg from './../assets/img/trade-up.webp'
import walletImg from './../assets/img/wallet.webp'

import defaultAvatar from './../assets/img/defaultAvatar.png'
import { PageContext } from '../context'
import buyNft from '../contracts/BuyNFT'

export default function VotesTopic ({vote}) {
    
    const navigate = useNavigate()
    const timerStopped = useRef(false)
    const [time, setTime] = useState(`0S`)

    const service = useContext(PageContext)

    useEffect(() => {
        const interval = setInterval(() => {
            const dateLeft = Math.max(0, !vote.status ? new Date(vote.endDate).getTime() - new Date().getTime() : 0)
            if (dateLeft === 0) {
              timerStopped.current = true
              clearInterval(interval)
            }
            const day = Math.floor(dateLeft / 86400000)
            const hours = Math.floor(dateLeft / 3600000 % 24)
            const minutes = Math.floor(dateLeft / 60000 % 60)
            const seconds = Math.floor(dateLeft / 1000 % 60)
            if (day > 0) {
                setTime(`${day}D`)
            } else if (hours > 0) {
                setTime(`${hours}H`)
            } else if (minutes > 0) {
                setTime(`${minutes}M`)
            } else if (seconds > 0) {
                setTime(`${seconds}S`)
            }
          }, 1000);
        return () => !timerStopped.current ? clearInterval(interval) : () => {};
    }, [])

    const [totalPlayers, setTotalPlayers] = useState(0)

    useEffect(() => {
        async function funcTotalPlayers () {
            await buyNft.daoTotalMembers().then(res => {
                setTotalPlayers(res)
            })
        }

        funcTotalPlayers()
    }, [service.currentAccount])

    return (
        <div className="vote-topic__item-block">
            <div className="vote-topic__top">
                <div className="vote-topic__card">
                    <div className="vote-topic__themes vote-topic__themes2">
                        <img className="vote-topic__themes-img" src={vote.topic === 'Gameplay' ? gameplayImg : (vote.topic === 'Economy' ? walletImg : (vote.topic === 'Development' ? tradeUpImg : (vote.topic === 'Punishments' ? punishmentsImg : (vote.topic === 'Website' ? websiteImg : (vote.topic === 'Other' ? starImg : flagImg )))))} alt=""/>
                        <p className="vote-topic__themes-name">
                            {vote.topic}
                        </p>
                    </div>
                    <p className="vote-topic__date vote-topic__date2">
                        {new Date(vote.date).toLocaleDateString()}
                    </p>
                </div>
                <div className="vote-topic__grid">
                    <p className="vote-topic__title-name vote-topic__title">
                        Title: 
                    </p>
                    <p className="vote-topic__title-some">
                        {vote.title}
                    </p>
                    <div className="vote-topic__cr-block">
                        <p className="vote-topic__title vote-topic__title-cr">
                            Created:
                        </p>
                        <p className="vote-topic__date vote-topic__date3">
                            {new Date(vote.date).toLocaleDateString()}
                        </p>
                    </div>
                    <div className="vote-topic__created">
                        <div className="vote-topic__player">
                        <p className="vote-topic__name vote-topic__name1">
                            {vote.name}
                        </p>
                        <div className="vote-topic__avatar" style={{
                            backgroundImage: vote.avatar != null ? `url(${vote.avatar})` : `url(${defaultAvatar})`
                        }}>
                            <div className="vote-topic__clan"/>
                        </div>
                        <div className="vote-topic__address-block">
                            <p className="vote-topic__name vote-topic__name2">
                                {vote.name}
                            </p>
                            <p className="vote-topic__address">
                                {truncateAddress(vote.from)}
                            </p>
                        </div>
                        </div>
                        <p className="vote-topic__date vote-topic__date1">
                            {new Date(vote.date).toLocaleDateString()}
                        </p>
                    </div>
                </div>
                <div className="vote-topic__info">
                    <div className="vote-topic__votes" style={{
                            display: service.currentAccount == null && 'none'
                        }}>
                        <p className="vote-topic__votes-title" >
                            Already voted:
                        </p>
                        <p className="vote-topic__votes-count" >
                            {vote.votesTotal} / {Math.max(totalPlayers, vote.votesTotal)} members
                        </p>
                    </div>
                    <div className="vote-topic__votes-compaire">
                        <div className="vote-topic__votes-yes">
                        <span className="vote-topic__yes-color"/>
                        <p className="vote-topic__compaire-title">
                            Yes
                        </p>
                        <p className="vote-topic__proc">
                            {Math.round((vote.votesYes + vote.votesNo) !== 0 ? vote.votesYes/(vote.votesYes + vote.votesNo) * 100 : 0)}%
                        </p>
                        </div>
                        <div className="vote-topic__votes-no">
                        <span className="vote-topic__no-color"/>
                        <p className="vote-topic__compaire-title">
                            No
                        </p>
                        <p className="vote-topic__proc">
                            {Math.round((vote.votesYes + vote.votesNo) !== 0 ? vote.votesNo/(vote.votesYes + vote.votesNo) * 100 : 0)}%
                        </p>
                        </div>
                    </div>
                    <div className="vote-topic__themes vote-topic__themes1">
                        <img className="vote-topic__themes-img" src={vote.topic === 'gameplay' ? gameplayImg : (vote.topic === 'economy' ? walletImg : (vote.topic === 'development' ? tradeUpImg : (vote.topic === 'punishments' ? punishmentsImg : (vote.topic === 'website' ? websiteImg : (vote.topic === 'others' ? starImg : flagImg )))))} alt=""/>
                        <p className="vote-topic__themes-name">
                            {vote.topic}
                        </p>
                    </div>
                </div>
            </div>
            <div className="vote-topic__bottom">
                <div className="vote-topic__status">
                    <p className="vote-topic__status-name">
                        Status:
                    </p>
                    <p className="vote-topic__status-state">
                        {!vote.status ? 'Open' : `Finished, ${(vote.votesYes + vote.votesNo) > (totalPlayers / 100 * 10) ? (vote.votesYes > vote.votesNo ? 'Accepted' : 'Rejected') : 'No quorum'}`}
                    </p>
                </div>
                <div className="vote-topic__time">
                    <p className="vote-topic__time-name">
                        Time remainning:
                    </p>
                    <p className="vote-topic__time-state">
                        {time}
                    </p>
                </div>
                <div className="vote-topic__add">
                    <div className="vote-topic__status">
                        <p className="vote-topic__status-name">
                            Status:
                        </p>
                        <p className="vote-topic__status-state">
                            {!vote.status ? 'Open' : `Finished, ${(vote.votesYes + vote.votesNo) > (totalPlayers / 100 * 10) ? (vote.votesYes > vote.votesNo ? 'Accepted' : 'Rejected') : 'No quorum'}`}
                        </p>
                    </div>
                    <div className="vote-topic__time">
                        <p className="vote-topic__time-name">
                            Time remainning:
                        </p>
                        <p className="vote-topic__time-state">
                            {time}
                        </p>
                    </div>
                </div>
                <button className="vote-topic__redirect" onClick={() => {
                    safeNavigate(navigate, generateUrl("/dao/voting/:paramTopic/:voteId", {
                        paramTopic: vote.topic,
                        voteId: vote.id
                    }))
                }}>
                    Details
                </button>
            </div>
            <p className="vote-topic__can" style={{
                display: vote.yourVote == null && vote.status && 'none'
            }}>
                {vote.yourVote != null && vote.status && 'You may withdraw your voice'}
            </p>
        </div>
    )
}