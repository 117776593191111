import { useContext, useState, useRef, useEffect } from "react"
import gsap from "gsap";
import { truncateAddress, useQuery, inIframe } from "../utils";
import config from "../config"
import { PageContext } from "../context"

import UserImg from './images/UserImg';
import CheckImg from './images/CheckImg'

import MetaMask from '../assets/img/MetaMask_Foxsvg.png'
import WhitePaper from '../assets/WHITEPAPER.docx'
import api from "../fetch";
import { Link } from "react-router-dom";


export default function Menu ({ q, onHandleConnect, onLogout, setMenuOpen, setAsidePage, role }) {

    const [animSettings, setAnimSettings] = useState(false)

    const settingsAnim1 = useRef()
    const settingsAnim2 = useRef()


    useEffect(() => {
      settingsAnim1.current = gsap.timeline({paused: true}).to(q('.right-content__bottom'), {
        duration: 1,
        opacity: 0,
        display: 'none',
      }).to(q('.menu-connect__icon-connected'), {
        opacity: 0,
        duration: 0.5,
      }).to(q('.menu-connect__fileload-bg'), {
        display: 'block',
        opacity: 1,
        duration: 0.5,
      }).to(q('.menu-connect__fileload'), {
        display: 'block'
      }).to('.menu-connect__icon-some', {
        filter: 'brightness(50%)',
        duration: 0.5
      })
    }, [])

    useEffect(() => {
      settingsAnim2.current = gsap.timeline({paused: true}).to(q('.right-content__top-main'), {
        duration: 1,
        opacity: 0,
        display: 'none'
      }).to(q('.right-content__top-settings'), {
        display: 'block',
        opacity: 1,
        duration: 1
      })
    }, [])

    useEffect(() => {
      if (animSettings) {
        settingsAnim1.current.play()
        settingsAnim2.current.play()
      } else {
        settingsAnim2.current.reverse()
        setTimeout(() => {
          settingsAnim1.current.reverse()
        }, 1000)
      }
    }, [animSettings])

    const service = useContext(PageContext)

    async function inputFile(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      const avatar = await new Promise((resolve, reject) => {
        reader.onloadend = async () => {
          const base64String = reader.result
              .replace('data:', '')
              .replace(/^.+,/, '');
          await api.post("/user/updateAvatar", {
            avatar: base64String
          }, service.authCallback)
          resolve(`url(${reader.result})`)
        }
        reader.readAsDataURL(file);
      })
      service.setYourAvatar(avatar)
    }

    function clickLink() {
      setMenuOpen(false)
    }

    const [newName, setNewName] = useState('')
    const [newEmail, setNewEmail] = useState('')

    const updateSettings = async (e) => {
      e.preventDefault()
      async function setNameFunc() {
        if (newName !== '') {
          await api.post("/user/setName", {
            name: newName
          }, service.authCallback)
          service.setName(newName)
          setNewName('')
        }
      }
      async function setEmailFunc() {
        if (newEmail !== '') {
          await api.post('/user/setEmail', {
            email: newEmail
          }, service.authCallback)
          service.setEmail(newEmail)
          setNewEmail('')
          service.setVerified(false)
        }
      }

      await Promise.all([setNameFunc(), setEmailFunc()])
      setAnimSettings(false)
    }

    const displayChainId = config.chains[service.chainId]

    return (
        <div className="menu">
          { !inIframe() ? <button className="menu__btn-close" onClick={() => {
            setMenuOpen(false)
          }}></button> : null }
          <div className="menu__container">
            <div className="menu__header menu-header">
              <img className="menu-header__logo" src="/assets/logo.svg" alt="Logo" />
              <div className="menu-header__connect-block menu-connect">
                <p className="menu-connect__status">
                  <span className="menu-connect__status-connect">
                    Connected
                  </span>
                  <span className="menu-connect__status-disconnect">
                    Connect Wallet
                  </span>
                </p>
                <button className="menu-connect__icon" style={{
                }}>
                  <div className="menu-connect__icon-clan" style={{
                    // display: (service.currentAccount == null || service.clan === null) ? 'none' : 'block',
                    display: 'none',
                    backgroundImage: service.yourClanAvatar
                  }}/>
                  <div className="menu-connect__icon-full">
                    <div className="menu-connect__icon-some" style={{
                      display: service.yourAvatar === 'url(null)' ? 'none' : 'block',
                      backgroundImage: service.currentAccount != null ? `${service.yourAvatar}` : ''
                    }}/>
                    <UserImg className="menu-connect__icon-some" style={{
                      display: service.yourAvatar === 'url(null)' ? 'block' : 'none'
                    }}/>
                    <svg className="menu-connect__icon-login" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                      <use xlinkHref="/assets/sprite.svg#login"></use>
                    </svg>
                    <svg className="menu-connect__icon-loading" width="26" height="28" viewBox="0 0 26 28" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                      <use xlinkHref="/assets/sprite.svg#loading"></use>
                    </svg>
                    <CheckImg className="menu-connect__icon-connected" />
                    <label htmlFor="input-file" className="menu-connect__fileload-bg"/>
                    <input accept="image/*" type="file" name="input-file" className="menu-connect__fileload" onChange={inputFile}/>
                  </div>
                </button>
                <button className="menu-connect__settings" onClick={() => {
                  setAnimSettings(true)
                }}>
                  Settings
                </button>
              </div>
            </div>
            <div className="menu__main menu-main">
              <div className="menu-main__top">
                <div className="menu-main__left">
                  <nav className="menu-main__nav">
                    <Link to='/dao/drops' className="menu-main__link" onClick={clickLink}>
                      Buy NFT box
                    </Link>
                    
                    <Link pnk="true" to='/dao/admin' className="menu-main__link menu-main__link--nav" style={{
                      display: role >= 2 ? 'block' : 'none'
                    }} onClick={clickLink}>
                      Admin panel
                    </Link>

                    <Link to='/dao/referrals' className="menu-main__link" onClick={clickLink}>
                      Referrals
                    </Link>

                     <Link to='/dao/voting' className="menu-main__link" onClick={clickLink}>
                      DAO
                    </Link> 

                    <Link to='/dao/inventory' className="menu-main__link" onClick={clickLink}>
                      Inventory
                    </Link> 

                  </nav>
                  <div className="menu-main__bottom menu-bottom menu-bottom2">
                    <a rel="noreferrer noopener" target="_blank" href={'https://constitution.rgw.zone/'} className="menu-bottom__descr">
                      Constitution
                    </a>
                    <a rel="noreferrer noopener" target="_blank" href={WhitePaper} className="menu-bottom__descr">
                      Whitepaper
                    </a>
                  </div>
                </div>
                <div className="menu-main__right menu-right">
                  <div className="menu-right__container">
                    <h2 className="menu-right__title">
                      Connect your <br /> wallet to log-in
                    </h2>
                    <div className="menu-right__bottom">
                      <button className="menu-right__link" onClick={onHandleConnect}>
                        Connect Wallet
                      </button>
                      <img className="menu-right__metamask" src={MetaMask} alt="MetaMask" />
                    </div>
                  </div>
                  <div className="menu-right__content right-content">
                    <div className="right-content__top">
                      <div className="right-content__top-main">
                        <h2 className="right-content__title" onClick={(e) => {
                          setAnimSettings(true)
                        }}>
                          {service.name}
                        </h2>
                        <p className="right-content__subtitle">
                          @{service.name}
                        </p>
                        <div className="right-content__status">
                          <p className="right-content__status-title">
                            Status:
                          </p>
                          <p className="right-content__status-descr">
                            {service.displayRole}
                          </p>
                        </div>
                        <div className="right-content__dao" onClick={(e) => {
                          if (!inIframe()) 
                            setMenuOpen(false)
                          else
                            window.parent.postMessage('https://95.216.218.18.sslip.io/voting', "*")
                        }}>
                          <p className="right-content__status-title right-content__dao-title">
                            DAO:
                          </p>
                          <p className="right-content__status-descr right-content__dao-descr">
                            {service.balanceVotes == null ? 0 : service.balanceVotes} coins
                          </p>
                        </div>
                        <Link to='/dao/referrals' className="right-content__switch" onClick={() => {
                          if (!inIframe()) {
                            setMenuOpen(false)
                            // setAsidePage("rewards")
                          } else {
                            window.parent.postMessage('https://95.216.218.18.sslip.io/voting', "*")
                          }
                        }}>
                          Referral &amp;&nbsp;Rewards
                        </Link>
                      </div>
                      <div className="right-content__top-settings settings">
                        <div className="settings__top">
                          <button className="settings__btn" onClick={() => {
                            setAnimSettings(false)
                          }}>
                            <svg className="settings__svg" width="20" height="20" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                              <use className="settings__svg-use" xlinkHref="/assets/sprite.svg#exit-settings"></use>
                            </svg>
                          </button>
                          <h3 className="settings__title">
                            Settings
                          </h3>
                        </div>
                        <div className="settings__bottom">
                          <form action="/" className="settings__form" onSubmit={updateSettings}>
                            <h4 className="settings__input-title">
                              Name
                            </h4>
                            <input type="text" className="settings__input" placeholder={service.name} value={newName} onChange={(e) => {
                              if (e.target.value.length <= 12) {
                                const value = e.target.value.trim().replace(/[^A-Za-z\s]/g, '');
                                setNewName((oldValue) => {
                                  console.log(value)
                                  if (value === '') {
                                    return '';
                                  } else {
                                    return value;
                                  }
                                })
                              }
                            }}/>
                            <div className="settings__email">
                              <h4 className="settings__input-title">
                                Email {`(${service.verified ? "Verified" : "Not verified"})`}
                              </h4>
                              <button className="settings__delete" onClick={async () => {
                                await api.post('/user/setEmail', {
                                  email: null
                                }, service.authCallback)
                                service.setEmail('')
                                setNewEmail('')
                                service.setVerified(false)
                              }}>
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                  <g id="SVGRepo_iconCarrier"> 
                                    <path d="M10 11V17" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> 
                                    <path d="M14 11V17" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> 
                                    <path d="M4 7H20" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> 
                                    <path d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> 
                                    <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> 
                                  </g>
                                </svg>
                              </button>
                            </div>
                            <input type="text" className="settings__input" placeholder={service.email} value={newEmail} onChange={(e) => setNewEmail(e.target.value)} />
                            <p className="settings__policy">
                              You agree to our <a href="https://drive.google.com/file/d/1_M5_PJ_9yzcGRk0-ZbxUhYeZyBhI3hz4/view?usp=drive_link" target={"_blank"} rel="noreferrer noopener" className="settings__policy--link">privacy policy</a> by adding your email.
                            </p>
                            <button className="settings__submit" >
                              Save
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="right-content__bottom">
                      <p className="right-content__cnw">
                        Connected wallet:
                      </p>
                      <p className="right-content__cnw-name">
                        MetaMask
                      </p>
                      <p className="right-content__network">
                        {displayChainId == null ? `Unknown (${service.chainId})` : displayChainId}
                      </p>
                      <p className="right-content__balance">
                        {Math.floor(service.currentBalance * 1e5) / 1e5}
                      </p>
                      {service.currentAccount != null && (
                        <button className="right-content__address-block" onClick={() => {
                          const textArea = document.createElement('textarea');
                          textArea.style.height = '0px';
                          textArea.value = service.currentAccount;
                          document.body.appendChild(textArea);
                          textArea.select();
                          document.execCommand("copy");
                          textArea.remove();
                        }}>
                          <p className="right-content__address-descr">
                            {truncateAddress(service.currentAccount)}
                          </p>
                          <div className="right-content__address-copy">
                            <svg className="right-content__address-svg" width="14" height="14" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                              <use className="footer-socials__svg" xlinkHref="/assets/sprite.svg#copy"></use>
                            </svg>
                          </div>
                        </button>
                      )}
                      <div className="right-content__exit-block" onClick={onLogout}>
                        <div className="right-content__exit-descr">
                          Log out
                        </div>
                        <button className="right-content__exit-btn">
                          <svg className="right-content__exit-svg" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            <use className="footer-socials__svg" xlinkHref="/assets/sprite.svg#exit"></use>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="menu-main__bottom menu-bottom menu-bottom1">
                <a rel="noreferrer noopener" target="_blank" href={'https://constitution.rgw.zone/'} className="menu-bottom__descr">
                  Constitution
                </a>
                {/* <a rel="noreferrer noopener" target="_blank" href={WhitePaper} className="menu-bottom__descr">
                  Whitepaper
                </a> */}
              </div>
              <div className="menu__socials">
                <a href="https://discord.gg/QvdcgcWn" className="item-socials__link">
                  <svg className="item-socials__svg" width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <use className="footer-socials__svg" xlinkHref="/assets/sprite.svg#discord"></use>
                  </svg>
                </a>
                <a href="#" className="item-socials__link">
                  <svg className="item-socials__svg" width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <use className="footer-socials__svg" xlinkHref="/assets/sprite.svg#instagram"></use>
                  </svg>
                </a>
                <a href="https://t.me/raidersgame" className="item-socials__link">
                  <svg className="item-socials__svg" width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <use className="footer-socials__svg" xlinkHref="/assets/sprite.svg#tg"></use>
                  </svg>
                </a>
                <a href="https://www.youtube.com/@rgwzone" className="item-socials__link">
                  <svg className="item-socials__svg" width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <use className="footer-socials__svg" xlinkHref="/assets/sprite.svg#youtube"></use>
                  </svg>
                </a>
              </div>
              <div className="menu-main__subbottom menu-subbottom">
                <ul className="menu-subbottom__list">
                  <li className="menu-subbottom__item">
                    <p className="menu-subbottom__descr menu-subbottom__descr1">
                      Raiders Of The Great Wastelands
                    </p>
                    <p className="menu-subbottom__descr menu-subbottom__descr2 menu-subbottom__descr2-first">
                      2024
                    </p>
                  </li>
                  <li className="menu-subbottom__item item-socials">
                    <a href="https://discord.gg/QvdcgcWn" className="item-socials__link">
                      <svg className="item-socials__svg" width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <use className="footer-socials__svg" xlinkHref="/assets/sprite.svg#discord"></use>
                      </svg>
                    </a>
                    <a href="#" className="item-socials__link">
                      <svg className="item-socials__svg" width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <use className="footer-socials__svg" xlinkHref="/assets/sprite.svg#instagram"></use>
                      </svg>
                    </a>
                    <a href="https://t.me/raidersgame" className="item-socials__link">
                      <svg className="item-socials__svg" width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <use className="footer-socials__svg" xlinkHref="/assets/sprite.svg#tg"></use>
                      </svg>
                    </a>
                    <a href="https://www.youtube.com/@rgwzone" className="item-socials__link">
                      <svg className="item-socials__svg" width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <use className="footer-socials__svg" xlinkHref="/assets/sprite.svg#youtube"></use>
                      </svg>
                    </a>
                  </li>
                  <li className="menu-subbottom__item">
                    <a rel="noreferrer noopener" target="_blank" href={'https://drive.google.com/file/d/1_M5_PJ_9yzcGRk0-ZbxUhYeZyBhI3hz4/view?usp=drive_link'} className="menu-subbottom__link">
                      Privacy policy
                    </a>
                    {/* <div className="menu-subbottom__descr-block">
                      <p className="menu-subbottom__descr menu-subbottom__descr1">
                        Powered by:
                      </p>
                      <a href="https://devspace.pw/" target="_blank" rel="noreferrer noopener" className="menu-subbottom__descr menu-subbottom__descr2 menu-subbottom__descr-dvo">
                        dev.space
                      </a>
                    </div> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    )
}