
import { useContext, useState } from "react"
import config from "../config"
import { fetchRequest, truncateAddress } from "../utils"
import { PageContext } from "../context"

import defaultAvatar from './../assets/img/defaultAvatar.png'
import buyNft from "../contracts/BuyNFT"
  
export default function AdminPlayer({ updateAllUsers, currentAccount, index, avatar, address, name, displayRole, role, adminRole, data }) {

    const service = useContext(PageContext)

    const [MLM, setMLM] = useState(false)

    return (
    <li className="admin__player player">
        <div className="player__left">
            <p className="player__index">
                #{index}
            </p>
            <div className="player__avatar" style={{
                backgroundImage: avatar !== 'url(undefined)' ? avatar : `url(${defaultAvatar})`
            }} />
            <p className="player__name">
                {name}
            </p>
            <p className="player__email">
                {data != null && data.address != null && truncateAddress(data.address)}
            </p>
            <p className="player__role" style={{
                color: displayRole === 'Superadmin' ? '#ffff00' : (displayRole === 'Duke' ? '#f40101' : (displayRole === 'Earl' ? '#00ffff' : (displayRole === 'Baron' ? '#00ff00' : '#efefef')))
            }}>
                {displayRole}
            </p>
            <p className="player__email">
                {data != null && data.email != null && data.email} ({data != null && data.verified != null && data.verified ? "Verified" : "Not verified"})
            </p>
        </div>
        <div className="player__right">
            <button className="player__btn player__btn-up-admin" style={{
                display: adminRole === 9 && role < 3 ? 'block' : 'none'
            }} onClick={async () => {
                await service.fetchRequest(`${config.apiEndpoint}/admin/users/setRole?from=${currentAccount}`, {
                    to: address,
                    role: 3,
                }, null).then(res => {
                    updateAllUsers()
                })
            }}>
                Duke
            </button>
            <button className="player__btn player__btn-up-lord" style={{
                display: adminRole >= 3 && role < 2 ? 'block' : 'none',
            }} onClick={async () => {
                await buyNft.setEarlRole(address, true)
                await service.fetchRequest(`${config.apiEndpoint}/admin/users/setRole?from=${currentAccount}`, {
                    to: address,
                    role: 2,
                }, null).then(res => {
                    updateAllUsers()
                })
            }}>
                Earl
            </button>
            <button className="player__btn player__btn-up-baron" style={{
                display: adminRole >= 2 && role < 1 ? 'block' : 'none',
            }} onClick={async () => {
                await service.fetchRequest(`${config.apiEndpoint}/admin/users/setRole?from=${currentAccount}`, {
                    to: address,
                    role: 1,
                }, null).then(res => {
                    updateAllUsers()
                })
            }}>
                Baron
            </button>
            <button className="player__btn player__btn-down" style={{
                display: adminRole >= 2 && adminRole > role && role > 0 ? 'block' : 'none',
                }} onClick={async () => {
                    if (role >= 2) {
                        await buyNft.setEarlRole(address, false)
                    }
                await service.fetchRequest(`${config.apiEndpoint}/admin/users/setRole?from=${currentAccount}`, {
                    to: address,
                    role: 0,
                }, null).then(res => {
                    updateAllUsers()
                })
            }}>
                Demote
            </button>
            <button className={MLM ? "player__btn-mlm player__btn-mlm--active" :"player__btn-mlm"} onClick={() => {
                setMLM(!MLM)
            }}>
                MLM
            </button>
        </div>
    </li>
)
}