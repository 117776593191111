import { Link } from "react-router-dom";
import config from "../config"
import { generateUrl, safeNavigate, truncateAddress } from "../utils"

import DeleteImg from './../components/images/DeleteImg';
import StatusContainer from './../components/StatusContainer';

import defaultAvatar from './../assets/img/defaultAvatar.png'
import api from "../fetch";
import { useContext, useEffect, useState } from "react";
import buyNft from "../contracts/BuyNFT";
import { PageContext } from "../context";

export default function SomeVote({someVote, setSomeVote, updateSomeVote, voteId, navigate, topic, thisVote, filteredVotes, setThisVote, currentAccount, setVotes, search, votes, role, setSureContainer, setSure}) {
    
  console.log(voteId)

  const [totalPlayers, setTotalPlayers] = useState(0)

  const service = useContext(PageContext)

  async function updateVoteFetch() {
    service.setLoad(true)
    await api.get(`/voting/${voteId}/info`, service.authCallback).then(res => {
      if (!res.error || res != null) {
        setSomeVote(res)
      }
    })
    await buyNft.daoTotalMembers().then(res => {
      console.log({res})
      setTotalPlayers(res)
    })
    service.setLoad(false)
  }

  useEffect(() => {

    async function SomeVoteFetch() {
      service.setLoad(true)
      await api.get(`/voting/${voteId}/info`, service.authCallback).then(res => {
        if (!res.error || res != null) {
          setSomeVote(res)
        }
      })
      await buyNft.daoTotalMembers().then(res => {
        console.log({res})
        setTotalPlayers(res)
      })
      service.setLoad(false)
    }

    SomeVoteFetch()
  }, [currentAccount])
  
  return (
    <div className="votes__vote">
      <button className="title" onClick={() => {
        safeNavigate(navigate, generateUrl("/dao/voting/:paramTopic/:voteId", {
          paramTopic: topic,
          voteId: ''
        }))
      }}>
        <span className="title__arrow"/>
        <p className="title__descr">
          All votes
        </p>
      </button>
      <div className="votes__container">
        <div className="vote-info__title-block">
          <div className="vote-info__title-left">
            <div className="vote-info__title-bottom">
              <p className="vote-data__descr vote-data__descr2">
                {(new Date(someVote.date).toLocaleDateString())}
              </p>
            </div>
          </div>
          <div className="vote-info__title-right">
            <p className="vote-data__descr">
              {(new Date(someVote.date).toLocaleDateString())}
            </p>
            <button className="vote-info__delete-btn" onClick={async (e) => {
              service.setLoad(true)
              try {
                await api.get(`/voting/${someVote.id}/remove`).then(res => {
                  service.fetchVotes().then(setVotes)
                })
                try {
                  safeNavigate(navigate, `/voting/${votes[votes.indexOf(thisVote) + 1].id}`, search)
                } catch(err) {
                  console.log(err)
                }
              } catch(err) {
                console.log(err)
              }
              service.setLoad(false)
            }} style={{
              display: (someVote.from === currentAccount || role >= 9) ? 'flex' : 'none'
            }}>
              <DeleteImg className="vote-info__delete"/>
            </button>
          </div>
        </div>
        <div className="vote__table">
          <p className="vote__title-some vote__title">
            Title:
          </p>
          <p className="vote__title-descr vote__descr">
            {someVote.title}
          </p>
          <p className="vote__creator-title vote__title">
            Creator:
          </p>
          <div className="vote__creator-block">
            <p className="vote__creator-name vote__descr" style={{
              display: someVote.name == null || someVote.name === '' ? 'none' : 'flex'
            }}>
              {thisVote.name}
            </p>
            <div className="vote__creator-avatar" style={{
              backgroundImage: someVote.from != null && someVote.avatar != null ? `url(${someVote.avatar})` : `url(${defaultAvatar})`
            }}>
            </div>
            <p className="vote__creator-address">
              {truncateAddress(someVote.from)}
            </p>
          </div>
          <p className="vote__descr-title vote__title">
            Description:
          </p>
          <p className="vote__descr-descr vote__descr">
            {someVote.description}
          </p>
        </div>
        <div className="vote-progress">
          <div className="left">
            <p className="voting-yes">Yes <span>{(someVote.votesYes + someVote.votesNo) !== 0 ? Math.round(someVote.votesYes / (someVote.votesYes + someVote.votesNo) * 100) : 0}%</span></p>
            <p className="voting-yes__right">{someVote.votesYes} Votes</p>
          </div>
          <div className="right">
            <p className="voting-no">No <span>{(someVote.votesYes + someVote.votesNo) !== 0 ? Math.round(someVote.votesNo/(someVote.votesYes + someVote.votesNo) * 100) : 0}%</span></p>
            <p className="voting-no__right">{someVote.votesNo} Votes</p>
          </div>
        </div>
        <div className="vote-statusbar">
          <div className="vote-statusbar__yes" style={{
            width: ((someVote.votesYes + someVote.votesNo) !== 0 ? someVote.votesYes / (someVote.votesYes + someVote.votesNo) * 100 : 0) + '%'
          }}></div>
          <div className="vote-statusbar__no" style={{
            width: ((someVote.votesYes + someVote.votesNo) !== 0 ? someVote.votesNo/ (someVote.votesYes + someVote.votesNo) * 100 : 0 )+ '%'
          }}></div>
          {/* <div className="statusbar-yes"></div> */}
          {/* <div className="statusbar-half"></div> */}
        </div>
        <div className="vote-actions">
          <div className="vote__btns" style={{
            display: currentAccount == null ? 'none' : 'flex'
          }}>
            {someVote.yourVote == null ? (
              <>
                <button className={someVote.yourVote === true ? "vote-yes-button vote-btn--active" : "vote-yes-button"} disabled={someVote.status || someVote.yourVote != null} onClick={
                  () => {
                    if (someVote.yourVote == null && !someVote.status) {
                      setSureContainer(true)
                      setSure({id: someVote.id, vote: true})
                    }
                  }
                } style={{
                  opacity: someVote.yourVote == null && !someVote.status ? 1 : 0.6,
                  cursor: someVote.yourVote == null && !someVote.status ? 'pointer' : 'default',
                }}>
                  Yes
                </button>
                <button className={someVote.yourVote === false ? "vote-no-button vote-btn--active" : "vote-no-button"} disabled={someVote.status || someVote.yourVote != null} onClick={
                  () => {
                    if (someVote.yourVote == null && !someVote.status) {
                      setSureContainer(true)
                      setSure({id: someVote.id, vote: false})
                    }
                  }
                } style={{
                  opacity: someVote.yourVote == null && !someVote.status ? 1 : 0.6,
                  cursor: someVote.yourVote == null && !someVote.status ? 'pointer' : 'default',
                }}>
                  No
                </button>
              </>
            ) : (
              <button className="vote__revoke" onClick={(e) => {
                service.setLoad(true)
                api.post(`/voting/${someVote.id}/revokeVote?from=${service.currentAccount}`, {
                  vote: someVote.yourVote
                }, service.authCallback).then(async res => {
                  !someVote.status ? (
                    await buyNft.daoRevokeVote(someVote.id, res.voteId, res.signature).finally(async () => {
                      await updateSomeVote(currentAccount)
                    })
                  ) : (
                    await buyNft.daoWithdrawVote(someVote.id, res.signature).finally(async () => {
                      await updateSomeVote(currentAccount)
                    })
                  )
                })
                service.setLoad(false)
              }}>
                {!someVote.status ? 'Revoke' : 'Withdraw'}
              </button>
            )}
          </div>
          <p className="vote-actions__descr">{someVote.votesTotal} / {Math.max(totalPlayers, someVote.votesTotal)} members already voted</p>
          {/* <p className="vote-actions__descr vote-actions__descr-next" style={{
            display: (filteredVotes.indexOf(thisVote) + 1) < filteredVotes.length  ? 'block' : 'none'
          }} onClick={() => {
            try {

              const myVote = filteredVotes[filteredVotes.indexOf(thisVote) + 1]
              const topic = myVote.topic == null ? "all" : myVote.topic
              console.log({ topic })
              safeNavigate(navigate, `/dao/voting/${topic}/${myVote.id}`, search)
            } catch(err) {
              console.log(err)
            }
          }}>Next vote &rarr;</p>
          <div className="vote-actions__info">
            <p className="vote-actions__descr">{someVote.votesTotal} / {totalPlayers} members already voted</p>
            <p className="vote-actions__descr vote-actions__descr-next" style={{
              display: (filteredVotes.indexOf(thisVote) + 1) < filteredVotes.length  ? 'block' : 'none'
            }} onClick={() => {
              try {

                const myVote = filteredVotes[filteredVotes.indexOf(thisVote) + 1]
                const topic = myVote.topic == null ? "all" : myVote.topic
                console.log({ topic })
                safeNavigate(navigate, `/dao/voting/${topic}/${myVote.id}`, search)
              } catch(err) {
                console.log(err)
              }
            }}>Next vote &rarr;</p>
          </div> */}
        </div>
        <div className="vote-timer">
          <StatusContainer thisVote={someVote} totalPlayers={totalPlayers} setThisVote={setSomeVote} currentAccount={currentAccount} updateVoteFetch={updateVoteFetch}/>
          <div className="min-approval">
            <div className="min-approval__left">
              <p className="min-approval__descr">
                Support&nbsp;%
              </p>
              <p className="min-approval__descr min-approval__descr-light">
                {Math.round((someVote.votesYes + someVote.votesNo) !== 0 ? someVote.votesYes / (someVote.votesYes + someVote.votesNo) * 100 : 0)}&nbsp;%
              </p>
            </div>
            <div className="min-approval__right">
              <p className="min-approval__descr">
                Not support&nbsp;%
              </p>
              <p className="min-approval__descr min-approval__descr-light">
                {Math.round((someVote.votesYes + someVote.votesNo) !== 0 ? someVote.votesNo / (someVote.votesYes + someVote.votesNo) * 100 : 0)}&nbsp;%
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}