import BuyNFT from "./BuyNFT.json"
import MLM from "./MLM.json"
import IERC20 from "./IERC20.json"
import DAO from "./DAO.json"
import BN from "bn.js"
import api from "../fetch"
import Web3 from "web3/dist/web3.min.js"


const decimalPlaces = 6n;

const contractAddresses = {
    "RaidersAccessControl": "0x0fAAd61D92a75f0759D58dcA274609316D7cbf97",
    "RaidersDAO": "0x31a9f2c88C9fe42610d825ff0386CDA2012105d1",
    "RaidersNFTBox": "0xdfe3F4EC101709FD0f2c40909AF4b1B0B0b2e359",
    "RaidersNFTCharacter": "0x15aCc1B01aBE26d5B784DfC81124a49106107A79",
    "RaidersNFTLand": "0xAe27F64b8a41983b48E14a967B75451761607b73",
    "RaidersSale": "0x3d3a360Ee14592eDf087401DF269eb64B36154df",
    "RaidersMint": "0x5a9cFEc7365F27a405B96E3034D46eEd27af76Df",
    "RaidersMLM": "0x954B77AdEcEae05Aa9832901E959BEC0e11E4016",
    "RGC20": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "SYNC_BLOCK": 60661873
}

const tokenAddress = contractAddresses.RGC20

let buyNftContract = null
let mlmContract = null
let _web3 = null
let decimals = Number(decimalPlaces)

export function revertReason(ex) {
    return ex.message
}

export function isSetup() {
    return _web3 != null
}

export function setup() {
    if (_web3 == null) {
        _web3 = new Web3(window.ethereum)
    }
}

const fromWei = (number, unit) => {

	// value in wei would always be integer
	// 13456789, 1234
	const value = number

	// count number of zeros in denomination
	// 1000000 -> 6
	const numberOfZerosInDenomination = unit;

	if (numberOfZerosInDenomination <= 0) {
		return value.toString();
	}

	// pad the value with required zeros
	// 13456789 -> 13456789, 1234 -> 001234
	const zeroPaddedValue = value.padStart(numberOfZerosInDenomination, '0');

	// get the integer part of value by counting number of zeros from start
	// 13456789 -> '13'
	// 001234 -> ''
	const integer = zeroPaddedValue.slice(0, -numberOfZerosInDenomination);

	// get the fraction part of value by counting number of zeros backward
	// 13456789 -> '456789'
	// 001234 -> '001234'
	const fraction = zeroPaddedValue.slice(-numberOfZerosInDenomination).replace(/\.?0+$/, '');

	if (integer === '') {
		return `0.${fraction}`;
	}

	if (fraction === '') {
		return integer;
	}

	return `${integer}.${fraction}`;
};

export function uiAmount(amount) {
    return Number(fromWei(amount.toString(), decimals))
}

export async function getAccount() {
    return (await _web3.eth.getAccounts())[0]
}

export async function daoTotalMembers() {
    setup()
    if (_web3 == null)
        throw new Error("Web3 is not setup")
    const daoContract = new _web3.eth.Contract(DAO, contractAddresses.RaidersDAO);
    return Number(await daoContract.methods.totalMembers().call({ from: await getAccount() }))
}

window.daoGetVotes = daoGetVotes

export async function daoUpdate(characterBudget, villageBudget, votingPeriod) {
    if (_web3 == null)
        throw new Error("Web3 is not setup")
    const daoContract = new _web3.eth.Contract(DAO, contractAddresses.RaidersDAO)
    await daoContract.methods.update(characterBudget, villageBudget, votingPeriod).send({ from: await getAccount() })
}

export async function daoEnter(villages, characters) {
    if (_web3 == null)
        throw new Error("Web3 is not setup")
    const daoContract = new _web3.eth.Contract(DAO, contractAddresses.RaidersDAO)
    await daoContract.methods.enter(villages, characters).send({ from: await getAccount() })
}

export async function daoLeave(villages, characters) {
    if (_web3 == null)
        throw new Error("Web3 is not setup")
    const daoContract = new _web3.eth.Contract(DAO, contractAddresses.RaidersDAO)
    await daoContract.methods.leave(villages, characters).send({ from: await getAccount() })
}

export async function daoDoVote(votingId, vote, count, signature) {
    if (_web3 == null)
        throw new Error("Web3 is not setup")
    const daoContract = new _web3.eth.Contract(DAO, contractAddresses.RaidersDAO)
    await daoContract.methods.doVote(votingId, vote, count, signature).send({ from: await getAccount() })
}

export async function daoRevokeVote(votingId, voteId, signature) {
    if (_web3 == null)
        throw new Error("Web3 is not setup")
    const daoContract = new _web3.eth.Contract(DAO, contractAddresses.RaidersDAO)
    await daoContract.methods.doRevokeVote(votingId, voteId, signature).send({ from: await getAccount() })
}

export async function daoWithdrawVote(votingId, signature) {
    if (_web3 == null)
        throw new Error("Web3 is not setup")
    const daoContract = new _web3.eth.Contract(DAO, contractAddresses.RaidersDAO)
    await daoContract.methods.withdrawVotes(votingId, signature).send({ from: await getAccount() })
}

export async function daoGetVotes(votingId) {
    if (_web3 == null)
        throw new Error("Web3 is not setup")
    const daoContract = new _web3.eth.Contract(DAO, contractAddresses.RaidersDAO)
    return Number(await daoContract.methods.getVotes(await getAccount(), votingId).call({ from: await getAccount() }))
}

export async function daoCheckVillageTransfer(tokenId) {
    if (_web3 == null)
        throw new Error("Web3 is not setup")
    const daoContract = new _web3.eth.Contract(DAO, contractAddresses.RaidersDAO)
    try {
        await daoContract.methods.checkVillageTransfer(tokenId).call()
        return false
    } catch {
        return true
    }
}

export async function daoCheckCharacterTransfer(tokenId) {
    if (_web3 == null)
        throw new Error("Web3 is not setup")
    const daoContract = new _web3.eth.Contract(DAO, contractAddresses.RaidersDAO)
    try {
        await daoContract.methods.checkCharacterTransfer(tokenId).call()
        return false
    } catch {
        return true
    }
}

export async function daoGetVotingBudget() {
    if (_web3 == null)
        throw new Error("Web3 is not setup")

    const daoContract = new _web3.eth.Contract(DAO, contractAddresses.RaidersDAO)
    return Number(await daoContract.methods.getVotingBudget(await getAccount()).call())
}

export async function daoGetUsedWeightedBudget() {
    if (_web3 == null)
        throw new Error("Web3 is not setup")

    const daoContract = new _web3.eth.Contract(DAO, contractAddresses.RaidersDAO)
    return Number(await daoContract.methods.getUsedWeightedBudget(await getAccount()).call())
}


export async function allowance(erc20Address) {
    if (_web3 == null)
        throw new Error("Web3 is not setup")
    const erc20Contract = new _web3.eth.Contract(IERC20, erc20Address)
    return new BN(await erc20Contract.methods.allowance(await getAccount(), contractAddresses.RaidersSale).call())
}

export async function balanceOf(erc20Address) {
    if (_web3 == null)
        throw new Error("Web3 is not setup")
    const erc20Contract = new _web3.eth.Contract(IERC20, erc20Address)
    return new BN(await erc20Contract.methods.balanceOf(await getAccount()).call())
}

export async function approve(erc20Address, amount) {
    if (_web3 == null)
        throw new Error("Web3 is not setup")
    const erc20Contract = new _web3.eth.Contract(IERC20, erc20Address)

    await erc20Contract.methods.approve(contractAddresses.RaidersSale, amount).send({ from: await getAccount() })
    return await allowance(erc20Address)
}

export async function preClaim(signature, pos, token, value, id, quantity, address, referer) {
    if (buyNftContract == null)
        buyNftContract = new _web3.eth.Contract(BuyNFT, contractAddresses.RaidersSale)

    window.buyNftContract = buyNftContract

    /*if (address.toLowerCase() != (await getAccount()).toLowerCase()) {
        throw new Error('Wrong account')
    }*/
    return await buyNftContract.methods.claim(signature, pos, token, value, id, quantity, referer).estimateGas({from: await getAccount(), value: token === "0x0000000000000000000000000000000000000000" ? value : 0 })
}

export async function claim(signature, pos, token, value, id, quantity, address, referer) {
    if (buyNftContract == null)
        buyNftContract = new _web3.eth.Contract(BuyNFT, contractAddresses.RaidersSale)

    if (address.toLowerCase() != (await getAccount()).toLowerCase()) {
        throw new Error('Wrong account')
    }
    try {
        await buyNftContract.methods.claim(signature, pos, token, value, id, quantity, referer).call({from: await getAccount(), value: token === "0x0000000000000000000000000000000000000000" ? value : 0 })
    } catch (eede) {
        console.error("X", eede)
    }

    return await buyNftContract.methods.claim(signature, pos, token, value, id, quantity, referer).send({from: await getAccount(), value: token === "0x0000000000000000000000000000000000000000" ? value : 0 })
}

export async function incrementMint(data) {
    if (buyNftContract == null)
        buyNftContract = new _web3.eth.Contract(BuyNFT, contractAddresses.RaidersSale)
    
    await buyNftContract.methods.incrementMint(data).call({from: await getAccount(), value: 0 }).catch(console.error)

    return await buyNftContract.methods.incrementMint(data).send({from: await getAccount(), value: 0 })
}

export async function openBox(boxId, address) {

    if (buyNftContract == null)
        buyNftContract = new _web3.eth.Contract(BuyNFT, contractAddresses.RaidersSale)

    if (address.toLowerCase() != (await getAccount()).toLowerCase()) {
        throw new Error('Wrong account')
    }

    const data = buyNftContract.methods.openBox(boxId).encodeABI()

    return await new Promise((resolve, reject) => {
       _web3.eth.sendTransaction({
            from: address,
            to: contractAddresses.RaidersSale,
            data
        }).on('transactionHash', hash => {

            alert("Hash: " + hash)

            let retries = 0

            async function checkReceipt() {
                const receipt = await _web3.eth.getTransactionReceipt(hash)

                if (receipt) {

                    if (!receipt.status) {
                        reject("Transaction openBox reverted")
                        return
                    }

                    resolve(null)
                    return
                }
                
                if (++retries >= 20)
                    reject("Transaction openBox timed out")

                setTimeout(checkReceipt, 5000)
            }

            checkReceipt()
        }).on('error', console.error)
    })
}

window.openBox = openBox

export async function refundBox(boxId, address) {

    if (buyNftContract == null)
        buyNftContract = new _web3.eth.Contract(BuyNFT, contractAddresses.RaidersSale)

    if (address.toLowerCase() != (await getAccount()).toLowerCase()) {
        throw new Error('Wrong account')
    }

    return await buyNftContract.methods.refundBox(boxId).send({from: await getAccount(), value: 0 })

    //return await new _web3.eth.Contract(ABI, contractAddresses.RaidersMint).methods.receiveIncrementCommand(await getAccount(), 5, 10).send({ from: await getAccount() })
}

export function position(address) {
    return 0
}

export async  function balanceNft(nftAddress, address, tokenIds) {

    const ABI = [{
        "inputs": [
          {
            "internalType": "address[]",
            "name": "accounts",
            "type": "address[]"
          },
          {
            "internalType": "uint256[]",
            "name": "ids",
            "type": "uint256[]"
          }
        ],
        "name": "balanceOfBatch",
        "outputs": [
          {
            "internalType": "uint256[]",
            "name": "",
            "type": "uint256[]"
          }
        ],
        "stateMutability": "view",
        "type": "function"
    }]

    const result = (await  new _web3.eth.Contract(ABI, nftAddress).methods.balanceOfBatch(tokenIds.map(() => address), tokenIds).call()).map(n => Number(n))
    //{type: 'boxs', name: 'Raiders box #1', avatar: null, descr: '1 villages', price: '10 USDT'},

    const box = await api.get("/box")

    let inventory = []
    let index = 0

    for (let balance of result) {
        inventory.push(...Array(balance).fill({type: 'boxs', name: box[index].name, image: box[index].image, descr: box[index].description, price: '?'}))
        index++
    }

    return inventory
}

export async function getRaider() {
    setup()
    if (mlmContract == null)
        mlmContract = new _web3.eth.Contract(MLM, contractAddresses.RaidersMLM)

    const addr = await getAccount()

    const obj = await mlmContract.methods.getRaider(addr, tokenAddress).call({ from: "0x0000000000000000000000000000000000000000" })

    console.log({ obj })

    return {
        inviter: obj.inviter,
        level: Number(obj.level),
        referrals: Number(obj.referrals),
        volume: Number(obj.volume),
        levelVolume: Number(obj.levelVolume),
        levelReward: Number(obj.levelReward),
        reward: uiAmount(obj.reward),
        income: uiAmount(obj.income)
    }
}

export async function getPendingReward() {
    setup()
    if (mlmContract == null)
        mlmContract = new _web3.eth.Contract(MLM, contractAddresses.RaidersMLM)

    return uiAmount(await mlmContract.methods.getPendingReward(await getAccount(), tokenAddress).call())
}

export async function claimReward(address) {

    if (address.toLowerCase() != (await getAccount()).toLowerCase()) {
        throw new Error('Wrong account')
    }

    await mlmContract.methods.claim(tokenAddress).send({ from: await getAccount() })
}

export async function setLevel(address, level) {
    await mlmContract.methods.setLevel(address, level).send({ from: await getAccount() })
}

export async function setBlacklisted(address, blacklisted) {
    await mlmContract.methods.setBlacklisted(address, blacklisted).send({ from: await getAccount() })
}

export async function setEarlRole(address, isEarl) {
    setup()
    if (mlmContract == null)
        mlmContract = new _web3.eth.Contract(MLM, contractAddresses.RaidersMLM)

    await mlmContract.methods.setEarlRole(address, isEarl).send({ from: await getAccount() })
}

/**MLM admin update method */
export async function mlmUpdate(levels /**@type [[number, number]] */, /**@type number */ smrReward, /**@type number */ earlV, /**@type number */ earlR) {

    setup()
    if (mlmContract == null)
        mlmContract = new _web3.eth.Contract(MLM, contractAddresses.RaidersMLM)

    await mlmContract.methods.update(levels, smrReward, earlV, earlR).send({ from: await getAccount() })
}

/**MLM admin update method */
export async function mlmInfo() {

    setup()
    if (mlmContract == null)
        mlmContract = new _web3.eth.Contract(MLM, contractAddresses.RaidersMLM)

    const info = await mlmContract.methods.info().call()
    return info
}

window.mlmUpdate = mlmUpdate
window.mlmGet = mlmInfo

window.revoke = async function revoke(erc20Address) {
    if (_web3 == null)
        throw new Error("Web3 is not setup")
    const erc20Contract = new _web3.eth.Contract(IERC20, erc20Address)

    const BN = _web3.utils.BN;
    const amount = 0

    await erc20Contract.methods.approve(contractAddresses.RaidersSale, amount).send({ from: await getAccount() })
}

window.approve = approve

const buyNft = { 
    setup,
    revertReason,
    allowance,
    balanceOf,
    approve,
    preClaim,
    claim,
    position,
    isSetup, 
    uiAmount, 
    balanceNft,
    openBox,
    refundBox,
    incrementMint,
    tokenAddress,
    decimalPlaces,
    contractAddresses,
    daoUpdate,
    daoEnter,
    daoLeave,
    daoDoVote,
    daoRevokeVote,
    daoCheckCharacterTransfer,
    daoCheckVillageTransfer,
    daoGetVotingBudget,
    daoGetUsedWeightedBudget,
    daoTotalMembers,
    daoWithdrawVote,
    getPendingReward,
    getRaider,
    claimReward,
    mlmUpdate,
    mlmInfo,
    setLevel,
    setBlacklisted,
    setEarlRole
}

window.buyNft = buyNft

export default buyNft

