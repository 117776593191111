
import { useEffect, useState } from 'react'
import api from '../fetch'
import list from './../assets/img/list.webp'
import AdminPlayer from './../components/AdminPlayer'

export default function AdminUsers({currentAccount, role}) {

    const [fetchAdmin, setFetchAdmin] = useState({
      settings: null,
      users: null,
    })

    const updateAllUsers = async () => {
      api.get("/admin/dao").then(res => {
        setFetchAdmin(res)
        setSuccess(res.settings.votingMinParticipants)
        setEndTime(res.settings.votingPeriod)
      })
    }

    const [success, setSuccess] = useState(fetchAdmin.settings != null ? fetchAdmin.settings.votingMinParticipants : 0)
    const [endTime, setEndTime] = useState(fetchAdmin.settings != null ? fetchAdmin.settings.votingPeriod : 0)

    useEffect(() => {
      updateAllUsers()
    }, [])

    return (
        <div className="admin__dao">
          <form action='' className="admin__updates" onSubmit={async (e) => {
            e.preventDefault()
            await api.post(`/admin/dao?from=${currentAccount}`, {
              votingMinParticipants: success,
              votingPeriod: endTime,
            }).then(async res => {
              await updateAllUsers()
            })
          }}>
            <div className="admin__update">
              <p className="admin__update-title">
                Success vote %
              </p>
              <input type="text" className="admin__update-input" value={success} onChange={(e) => {
                setSuccess(e.target.value)
              }} />
            </div>
            <div className="admin__update">
              <p className="admin__update-title">
                Voting time
              </p>
              <input type="text" className="admin__update-input" value={endTime} onChange={(e) => {
                setEndTime(e.target.value)
              }} />
            </div>
            <div className="admin__update">
              <p className="admin__update-title">
                Characters %
              </p>
              <input type="text" className="admin__update-input" />
            </div>
            <div className="admin__update">
              <p className="admin__update-title">
                Villages %
              </p>
              <input type="text" className="admin__update-input" />
            </div>
            <button className="admin__submit">
              Update
            </button>
          </form>
          <div className="admin__players-titles">
            <img src={list} alt="" className="admin__players-img" />
            <h3 className="admin__players-title">
                All users
            </h3>
          </div>
          <ul className="admin__players">
            {fetchAdmin.users != null ? fetchAdmin.users.map((x, i) => {
              return (
                <AdminPlayer updateAllUsers={updateAllUsers} currentAccount={currentAccount} name={x.nickname} key={i} index={i + 1} address={x.address} displayRole={x.displayRole} role={x.role} adminRole={role} data={x} avatar={x.avatar}/>
              )
            }) : console.log(fetchAdmin)}
          </ul>
        </div>
    )
}