import config from "./config";

class ResponseError extends Error {
    constructor(code, message) {
        super(message)
        this.code = code
    }
}

let ws;

let listeners = {}


async function get(url, authCallback) {
    const callback = (skip) => fetch(config.apiEndpoint + url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: "include"
    }).then(res => res.json()).then((res) => check(callback, res, authCallback, skip))
    return await callback(false)
}

async function post(url, data, authCallback) {
    const callback = (skip) => fetch(config.apiEndpoint + url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: "include"
    }).then(res => res.json()).then((res) => check(callback, res, authCallback, skip))
    return await callback(false)
}

async function check(callback, res, authCallback, skip) {
    if (res.statusCode === 401 && !skip) {
        const a1 = await authCallback().then(() => callback(true))
        console.log({a1}, 'a1')
        return a1
    } else if (res.statusCode === 200) {
        return res.data
    } else {
        throw new ResponseError(res.statusCode, res.message)
    }
}

function addListener(channel, callback) {
    listeners[channel] = callback
    if (ws != null && ws.readyState == WebSocket.OPEN)
        send({ type: 'subscribe', channel })
}

function removeListener(channel) {
    delete listeners[channel]
    if (ws != null && ws.readyState == WebSocket.OPEN)
        send({ type: 'unsubscribe', channel })
}

function websocket() {
    if (ws && ws.readyState == 1) {
        ws.close()
    }

    let token = window.localStorage.getItem('token')
    ws = new WebSocket(config.ws, token)
    ws.addEventListener('open', () => {
        for (let listener in listeners) {
            send({ type: 'subscribe', channel: listener })
        }
    })
    ws.addEventListener('message', message => {
        if (token != window.localStorage.getItem('token')) {
            ws.close()
        }
        const data = JSON.parse(message.data)
        if (listeners[data.channel])
            listeners[data.channel](data.payload)
    })
    ws.addEventListener('close', () => {
        if (listeners["chatMessage"] != null)
            listeners["chatMessage"](null)
        setTimeout(websocket, 1000)
    })
}

function setToken(token) {
    ws.close()
    window.localStorage.setItem('token', token)
}

function send(data) {
    if (ws != null && ws.readyState == WebSocket.OPEN)
        ws.send(JSON.stringify(data))
}

websocket()


const api = { get, post, addListener, removeListener }

export default api