import { useState, useRef, useEffect } from 'react'

const Compoment = (props) => {
    const thisVote = props.thisVote
    const currentAccount = props.currentAccount

    const [statusVote, setStatusVote] = useState({ status: false, day: 0, hours: 0, minutes: 0, seconds: 0 })

    const timerStopped = useRef(false)

    useEffect(() => {
      function updateTimer() {
        const dateLeft = Math.max(0, !thisVote.status ? new Date(thisVote.endDate).getTime() - new Date() : 0)
        const day = Math.floor(dateLeft / 86400000)
        const hours = Math.floor(dateLeft / 3600000 % 24)
        const minutes = Math.floor(dateLeft / 60000 % 60)
        const seconds = Math.floor(dateLeft / 1000 % 60)
        setStatusVote({ status: thisVote.status, day: day, hours: hours, minutes: minutes, seconds: seconds })
      }

      if (!thisVote.status && new Date(thisVote.endDate).getTime() - new Date() >= 0) {
        updateTimer()
        const interval = setInterval(() => {
          const dateLeft = Math.max(0, !thisVote.status ? new Date(thisVote.endDate).getTime() - new Date() : 0)
          if (dateLeft === 0) {
            timerStopped.current = true
            clearInterval(interval)
          }
          updateTimer()
        }, 1000);
        return () => !timerStopped.current ? clearInterval(interval) : () => {};
      } else {
        setStatusVote({ status: true, day: 0, hours: 0, minutes: 0, seconds: 0 })
      }
    }, [currentAccount, thisVote])
    
    return(
      <div className="remaining-time">
        <div className="remaining-time__top">
          <p className="remaining-time__status">
            Status: {!statusVote.status ? 'Open' : `Finished, ${(thisVote.votesYes + thisVote.votesNo) > (props.totalPlayers / 100 * 10) ? (thisVote.votesYes > thisVote.votesNo ? 'Accepted' : 'Rejected') : 'No quorum'}`}
          </p>
          <p className="remaining-time__timeleft">
            Time remainning:
          </p>
        </div>
        <div className="remaining-time__bottom">
          <p className="remaining-time__time remaining-time__days">
            {statusVote.day} D
          </p>
          <p className="remaining-time__time remaining-time__hours">
            {statusVote.hours} H
          </p>
          <p className="remaining-time__time remaining-time__minutes">
            {statusVote.minutes} M
          </p>
          <p className="remaining-time__time remaining-time__seconds">
            {statusVote.seconds} S
          </p>
        </div>
      </div>
    )
}

export default Compoment;