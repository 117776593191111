import { cloneElement, Children, useState, useEffect } from "react";

export default function BackgroundSwiper(props) {
    const [activeIndex, setActiveIndex] = useState(-1)
    const duration = 8

    const children = Children.map(props.children, (child, index) => {
        let style = {
            transition: `transform ${duration}s, opacity ${duration - 0.2}s`,
            position: 'absolute',
            top: 0,
            zIndex: props.children.length - index,
            opacity: 0
        }

        let prevIndex = activeIndex - 1
        if (prevIndex < 0)
            prevIndex = props.children.length - 1

        if (index == prevIndex) {
            style.transform = 'scale(1.3)'
            style.opacity = 0
        }
        if (index == activeIndex) {
            style.transform = 'scale(1.3)'
            style.opacity = 1
        }
        return cloneElement(child, { style: Object.assign(style, child.props.style) });
    });

    useEffect(() => {
        setActiveIndex( activeIndex => (activeIndex + 1) % children.length)
        const interval = setInterval(() => {
            setActiveIndex( activeIndex => (activeIndex + 1) % children.length)
        }, duration * 1000 - 1000)

        return () => clearInterval(interval)
    }, [])

    return (
        <div className="root__bgs">
            {children}
        </div>
    )

}