import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom"

import AdminDrops from "./../pages/AdminDrops"
import AdminReq from "./../pages/AdminReq"
import AdminUsers from './../pages/AdminUsers'
import { truncateAddress } from "../utils"
import AdminMLM from "./Admin/AdminMLM"

export default function Admin({BoxsLoading, boxTopList, setBoxTopList, setDateForModal, isDropList, setIsModalDrops, service, currentAccount, owner, role, yourAvatar, q }) {

    document.title = 'Raiders • Admin Panel'
    const location = useLocation().pathname

    if (role < 9) return <Navigate to='/dao/voting' replace/>
    return (
        <div className="admin" >
            <div className="admin__container container">
                <div className="admin__top">
                    <Link to="/dao/voting" className="admin__back">
                        <span className="admin__back-arrow"/>
                        <h2 className="admin__title">
                            Admin panel
                        </h2>
                    </Link>
                    <h2 className="admin__title">
                        
                    </h2>
                    <div className="admin__top-rigth">
                        <p className="admin__admin">
                            Admin:
                        </p>
                        <p className="admin__name">
                            {owner}
                        </p>
                        <div className="admin__avatar" style={{
                            backgroundImage: currentAccount != null ? `${yourAvatar}` : ''
                        }}>
                        </div>
                        <p className="admin__address">
                            {truncateAddress(currentAccount)}
                        </p>
                    </div>
                </div>
                <div className="admin__chooses">
                    <Link to="/dao/admin/users" className={`admin__choose ${location === '/dao/admin/users' ? 'admin__choose--active' : ''} `}>
                        DAO
                    </Link>
                    <Link to="/dao/admin/mlm" className={`admin__choose ${location === '/dao/admin/mlm' ? 'admin__choose--active' : ''} `}>
                        MLM
                    </Link>
                    <Link to="/dao/admin/req" className={`admin__choose ${location === '/dao/admin/req' ? 'admin__choose--active' : ''} `}>
                        REQUESTS
                    </Link>
                    <Link to="/dao/admin/drops" className={`admin__choose ${location === '/dao/admin/drops' ? 'admin__choose--active' : ''} `}>
                        DROPS
                    </Link>
                </div>
                <div className="admin__main">
                    <Routes>
                        <Route path="/users" element={<AdminUsers currentAccount={currentAccount} role={role} />} />
                        <Route path="/mlm" element={<AdminMLM currentAccount={currentAccount} role={role}/>} />
                        <Route path="/req" element={<AdminReq currentAccount={currentAccount} />} />
                        <Route path="/drops" element={<AdminDrops boxTopList={boxTopList} setBoxTopList={setBoxTopList} setDateForModal={setDateForModal} isDropList={isDropList} BoxsLoading={BoxsLoading} setIsModalDrops={setIsModalDrops} q={q} currentAccount={currentAccount} service={service} />} />
                        <Route path='*' element={<Navigate to='/dao/admin/users' replace/>}/>
                    </Routes>
                </div>
            </div>
        </div>
    )
}