import { useState, useEffect, useRef } from "react"

import boxImg from './../assets/img/box.jpg'

import api from "./../fetch";
import buyNft, { claim, uiAmount } from './../contracts/BuyNFT.js'
import SuccessModal from "./SuccessModal";
import gsap from "gsap";

export default function DropsModal({ BoxsLoading, BoxsLoadingAdmin, service, animModalDrops, isModalDrops, setIsModalDrops, dateForModal, setDateForModal, currentAccount, finishDate, isDropList, isDropListAdmin, boxTopList }) {

    const [cardCount, setCardCount] = useState([0, 0, 0])

    const [claiming, setClaiming] = useState(['ready', 'ready', 'ready'])

    const [dates, setDates] = useState(dateForModal.admin ? isDropListAdmin : isDropList)

    useEffect(() => {
        setDates(dateForModal.admin ? isDropListAdmin : isDropList)
        setPhotoBox(dates[dateForModal.index].image)
    }, [dateForModal, isDropListAdmin, isDropList])

    const [cost, setCost] = useState(dates[dateForModal.index].price)

    const [allowance, setAllowance] = useState(0)
    const [tokenBalance, setTokenBalance] = useState(0)

    const [successMode, setSuccessMode] = useState('submit')
    const [successCount, setSuccessCount] = useState(0)
    const [successPrice, setSuccessPrice] = useState(0)

    const [photoBox, setPhotoBox] = useState(null)

    useEffect(() => {

        if (isModalDrops) {
            setCost(dates[dateForModal.index].price)

            setPhotoBox(dates[dateForModal.index].image)

            setCardCount(dates[dateForModal.index].quantity == null ? [0, 0, 0] : dates[dateForModal.index].quantity)

        } else {
            animModalDrops.current.reverse()
        }
    }, [isModalDrops])

    async function OrderLoading() {
        if (dateForModal.admin) {
            animModalDrops.current.play()
            return
        }
    }


    async function CheckBalance() {
        if (currentAccount == null)
            return



        await service.checkAllowance().then(allowance => {
            setAllowance(buyNft.uiAmount(allowance))
        }).catch(console.error)

        await service.checkBalance().then(balance => {
            setTokenBalance(buyNft.uiAmount(balance))
        })

    }

    useEffect(() => {
        let intvl = setInterval(() => {
            CheckBalance()
        }, 15000)

        CheckBalance()

        return () => clearInterval(intvl)

    }, [currentAccount])

    const successRef = useRef(null)
    const animSuccess = useRef()

    useEffect(() => {
        animSuccess.current = gsap.timeline({ paused: true }).to(successRef.current, {
            opacity: 1,
            display: 'flex',
            duration: 0.5
        })
    }, [successRef])

    async function inputFile(e) {
        const file = e.target.files[0];
        const reader = new FileReader();
        const avatar = await new Promise((resolve, reject) => {
          reader.onloadend = async () => {
            const base64String = reader.result
                .replace('data:', '')
                .replace(/^.+,/, '');
            await api.post("/admin/box/image", {
                id: e.target.dataset.id,
                image: base64String
            }, service.authCallback)
            await BoxsLoading()
            await BoxsLoadingAdmin()
            resolve(`url(${reader.result})`)
          }
          reader.readAsDataURL(file);
        })
        setPhotoBox(avatar)
    }

    function preClaim(i) {
        if (dates[dateForModal.index].orderId[i] == null)  
            return Promise.resolve(null)

        return api.post(`/box/claim?from=${currentAccount}`, {
            id: dates[dateForModal.index].orderId[i],
            pos: 0
        }, service.authCallback).then(async res => {
            setClaiming(old => old.map((x, j) => i === j ? 'processing' : old[j]))

            buyNft.preClaim(res.signature, res.pos, res.token, res.value, res.id, res.quantity, currentAccount, res.referer).then(() => {
                setClaiming(old => old.map((x, j) => i === j ? 'ready' : old[j]))
            }).catch((ex) => {
                const reason = buyNft.revertReason(ex)
                if (reason.indexOf('Order closed') !== -1)
                    setClaiming(old => old.map((x, j) => i === j ? 'done' : old[j]))
                else {
                    alert(reason)
                    setClaiming(old => old.map((x, j) => i === j ? 'done' : old[j]))
                }
            })
        }).then(() => true)
    }

    function openModal() {
        CheckBalance().then(() => {
            setDateForModal(oldDateForModal => Object.assign(oldDateForModal, { loading: false }))
            animModalDrops.current.play()
        })
    }

    useEffect(() => {
        async function run() {
            const res = (await Promise.all(new Array(3).fill(0).map((x, i) => preClaim(i)))).filter(x => x == null)
            if (isModalDrops && res) {
                openModal()
            }
        }

        run()
    }, [isModalDrops])

    useEffect((state) => {
        if (state == 'processing')
            return
        if (isModalDrops && animModalDrops.current.reversed()) {
            openModal()
        }
    }, [claiming])

    return (
        <div className="drops__modal drops-modal" onClick={(e) => {
            if (e.target === document.querySelector('.drops__modal')) {
                setIsModalDrops(false)
            }
        }}>
            <div className="drops-modal__container">
                <button className="drops-modal__close" onClick={() => {
                    setIsModalDrops(false)
                }}>
                    <svg style={{ pointerEvents: 'none' }} className="drops-modal__svg" width="23px" height="23px" viewBox="0 0 23 23" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <g style={{ pointerEvents: 'none' }} stroke="none" strokeWidth="1" fill="#fff" fillRule="evenodd">
                            <rect style={{ pointerEvents: 'none' }} transform="translate(11.313708, 11.313708) rotate(-45.000000) translate(-11.313708, -11.313708)" x="10.3137085" y="-3.6862915" width="2" height="30"></rect>
                            <rect style={{ pointerEvents: 'none' }} transform="translate(11.313708, 11.313708) rotate(-315.000000) translate(-11.313708, -11.313708) " x="10.3137085" y="-3.6862915" width="2" height="30"></rect>
                        </g>
                    </svg>
                </button>
                <div className="drops-modal__imgs">
                    <div className="drops-modal__img" style={{
                        backgroundImage: photoBox == null ? `url(${boxImg})` : `url(${photoBox})`
                    }}>
                        <div className="drops-modal__imgs-loading" style={{
                            display: !dateForModal.admin ? 'none' : 'block'
                        }}>
                            <div className="drops-modal__imgs-bg" />
                            <input accept="image/*" type="file" name="drops-modal__imgs-file" className="drops-modal__imgs-file" data-id={dates[dateForModal.index].boxId} onChange={inputFile}/>
                        </div>
                    </div>
                </div>
                <div className="drops-modal__bottom">
                    <h2 className="drops-modal__title">
                        {dates[dateForModal.index].name}
                    </h2>
                    <p className="drops-modal__subtitle">
                        {/* Enter your parameters */}
                        {dates[dateForModal.index].description}
                    </p>
                    {[1, 2, 3].map((x, i) => {

                        const totalPrice = dates[dateForModal.index].price[i] * dates[dateForModal.index].quantity[i]

                        const statusBtnText = () => {
                            if (dateForModal.admin) {
                                return [true, 'Submit']
                            } else {
                                switch (dates[dateForModal.index].status[i]) {
                                    case 'approved':
                                        if (allowance < totalPrice && claiming[i] != 'processing') {
                                            return tokenBalance < totalPrice ? [false, 'Not enough'] : [true, 'Give permission for purchase']
                                        } else {
                                            if (claiming[i] == 'ready' || claiming[i] == 'done') {
                                                if (tokenBalance < totalPrice && claiming[i] != 'done') {
                                                    return [false, `You need ${totalPrice} USDT`]
                                                } else {
                                                    return claiming[i] == 'done' ? [false, `Purchased`] : [true, `Purchase selected for ${totalPrice} USDT`]
                                                }
                                            } else {
                                                return [false, 'Sending']
                                            }
                                        }
                                    case 'waiting':
                                        return boxTopList.status == 'finish' ? [false, 'No items available on this category'] : [true, 'Cancel Order'] 
                                    case 'declined':
                                        return [false, 'Declined']
                                    case 'none': 

                                        if (boxTopList.status === 'finish') 
                                            return [false, 'No items available in this category']
                                        if (tokenBalance < dates[dateForModal.index].price[i] * cardCount[i])
                                            return [false, 'Not enough balance'];

                                        return [true, 'Submit order']
                                    case 'cancelled':
                                        if (tokenBalance < dates[dateForModal.index].price[i] * cardCount[i])
                                            return [false, 'Not enough balance'];
                                        return [true, 'Submit order']
                                    default:
                                        return [false, dates[dateForModal.index].status[i]]
                                }
                            }
                        }

                        const [btnEnabled, btnStatus] = statusBtnText()

                        const statusText = () => {
                            if (dateForModal.admin) {
                                return [false, '', '#ffffff']
                            } else {
                                switch (dates[dateForModal.index].status[i]) {
                                    case 'approved':
                                        if (allowance < totalPrice) {
                                            return tokenBalance < totalPrice ? [true, 'Approved', '#87FF69'] : [true, 'Approved', '#87FF69']
                                        } else {
                                            if (claiming[i] == 'ready' || claiming[i] == 'done') {
                                                if (tokenBalance < totalPrice) {
                                                    return [true, 'Approved', '#87FF69']
                                                } else {
                                                    return claiming[i] == 'done' ? [true, 'Purchased', '#87FF69'] : [true, 'Approved', '#87FF69']
                                                }
                                            } else {
                                                return [true, 'Sending', '#87FF69']
                                            }
                                        }
                                    case 'waiting':
                                        return boxTopList.status == 'finish' ? [true, 'Waiting for approval...', '#FFFFFF'] : [true, 'Waiting for approval...', '#FFFFFF']
                                    case 'declined':
                                        return [true, 'Declined', '#E32D2D']
                                    case 'none': 
                                        if (boxTopList.status === 'finish') return [false, dates[dateForModal.index].status[i], '#FFFFFF']
                                        return [false, dates[dateForModal.index].status[i], '#FFFFFF']
                                    default:
                                        return [false, dates[dateForModal.index].status[i]]
                                }
                            }
                        }

                        const [statusEnabled, statusDescr, statusColor] = statusText()

                        return (
                            <form action="" key={i} className="drops-modal__block" onSubmit={async (e) => {
                                e.preventDefault()
                                if (dates[dateForModal.index].status[i] == "none" || dates[dateForModal.index].status[i] == "cancelled")
                                    if (tokenBalance < dates[dateForModal.index].price[i] * cardCount[i])
                                        return

                                if (!btnEnabled) {
                                    return
                                }

                                if (dateForModal.admin) {
                                    await api.post(`/admin/box?position=${i}`, {
                                        id: dates[dateForModal.index].boxId,
                                        priceCategory: Number(cost[i])
                                    }, service.authCallback).then(async res => {
                                        await BoxsLoading()
                                        await BoxsLoadingAdmin()
                                    })
                                } else {
                                    service.setLoad(true)
                                    if (dates[dateForModal.index].status[i] === 'none' || dates[dateForModal.index].status[i] === 'cancelled') {
                                        if (Number(cardCount[i]) > 0) {
                                            setSuccessCount(Number(cardCount[i]))
                                            setSuccessPrice(Number(cost[i]))
                                            setSuccessMode('submit')
                                            await api.post(`/box/order?position=${i}&from=${currentAccount}`, {
                                                id: dates[dateForModal.index].boxId,
                                                quantity: Number(cardCount[i])
                                            }, null).then(async res => {
                                                animSuccess.current.play()
                                                await BoxsLoading()
                                                await BoxsLoadingAdmin()
                                                await OrderLoading(dates[dateForModal.index].id)
                                                service.setLoad(false)
                                            }).catch((ex) => {
                                                console.error(ex)
                                                service.setLoad(false)
                                            })
                                        } else {
                                            service.setLoad(false)
                                        }
                                    } else if (dates[dateForModal.index].status[i] === 'waiting') {
                                        setSuccessMode('cancel')
                                        await api.post(`/box/cancel?from=${currentAccount}`, {
                                            id: dates[dateForModal.index].orderId[i]
                                        }, service.authCallback).then(async res => {
                                            animSuccess.current.play()
                                            await BoxsLoading()
                                            await BoxsLoadingAdmin()
                                            await OrderLoading(dates[dateForModal.index].id)
                                            service.setLoad(false)
                                        }).catch((ex) => {
                                            console.error(ex)
                                            service.setLoad(false)
                                        })
                                    } else if (dates[dateForModal.index].status[i] === 'approved') {
                                        if (allowance >= totalPrice) {
                                            setClaiming(old => old.map((x, j) => i == j ? 'processing' : old[j]))
                                            await api.post(`/box/claim?from=${currentAccount}`, {
                                                id: dates[dateForModal.index].orderId[i],
                                                pos: Number(await buyNft.position(currentAccount))
                                            }, service.authCallback).then(async res => {
                                                buyNft.claim(res.signature, res.pos, res.token, res.value, res.id, res.quantity, currentAccount, res.referer).then(() => {
                                                    service.setLoad(false)
                                                    setSuccessMode('payed')
                                                    setSuccessCount(Number(res.quantity))
                                                    animSuccess.current.play()
                                                }).then(() => {
                                                    service.setLoad(false)
                                                    setClaiming(old => old.map((x, j) => i == j ? 'done' : old[j]))
                                                }).catch((ex) => {
                                                    console.error(ex)
                                                    service.setLoad(false)
                                                    setClaiming(old => old.map((x, j) => i == j ? 'ready' : old[j]))
                                                })
                                            }).catch((ex) => {
                                                console.error(ex)
                                                service.setLoad(false)
                                            })
                                        } else {
                                            setClaiming(old => old.map((x, j) => i === j ? 'processing' : old[j]))


                                            function pow(x, y) {
                                                let z = 1n
                                                for (let i = 0; i < y; i++) {
                                                    z *= x
                                                }
                                                return z
                                            }

                                            //const BN = _web3.utils.BN;
                                            //const amount = (new BN()).pow(new BN(110)).sub(new BN(1));
                                            const amount = (BigInt(totalPrice) * pow(10n, buyNft.decimalPlaces)).toString()

                                            buyNft.approve(buyNft.tokenAddress, amount).then((allowance) => {
                                                service.setLoad(false)
                                                setAllowance(allowance)
                                            }).finally(() => {
                                                service.setLoad(false)
                                                setClaiming(old => old.map((x, j) => i === j ? 'ready' : old[j]))
                                            }).catch((ex) => {
                                                console.error(ex)
                                                service.setLoad(false)
                                            })
                                        }
                                    }
                                }
                            }}>
                                <div className="drops-modal__block-top">
                                    <div className="drops-modal__category">
                                        Price category:
                                        <span className="drops-modal__category-strong" style={{
                                            display: dateForModal.admin ? 'none' : 'block'
                                        }}>
                                            {dates[dateForModal.index].price[i]} USDT
                                        </span>
                                        <span className="drops-modal__category-name" style={{
                                            display: !statusEnabled ? 'block' : 'none'
                                        }}>
                                            ({i === 0 ? 'economical option' : (i === 1 ? 'base price' : 'safety option')})
                                        </span>
                                        <div className="drops-modal__category-inputs" style={{
                                            display: dateForModal.admin ? 'flex' : 'none'
                                        }}>
                                            <input type="text" className="drops-modal__category-input" value={cost[i]} onChange={(e) => {
                                                if (e.target.value.match(/^[0-9]{1,4}$/) || e.target.value === '') {
                                                    setCost((oldCost) => {
                                                        return oldCost.map((y, j) => {
                                                            if (i === j) {
                                                                return e.target.value
                                                            } else {
                                                                return y
                                                            }
                                                        })
                                                    })
                                                }
                                            }} />
                                            <p className="drops-modal__category-value">
                                                USDT
                                            </p>
                                        </div>
                                    </div>
                                    <p className="drops-modal__status" style={{
                                        display: statusEnabled ? 'block' : 'none'
                                    }}>
                                        Status: <span className="drops-modal__status-strong" style={{
                                            color: statusColor
                                        }}>{statusDescr}</span>
                                    </p>
                                </div>
                                <ul className="drops-modal__list" style={{
                                    display: dateForModal.admin ? 'none' : 'flex'
                                }}>
                                    <li className="drops-modal__item">
                                        <p className="drops-modal__subtext">
                                            Choose the amount
                                        </p>
                                        <div className="drops-modal__inputs">
                                            <button className="drops-modal__add" onClick={(e) => {
                                                e.preventDefault()
                                                if (dates[dateForModal.index].status[i] === 'none' || dates[dateForModal.index].status[i] === 'cancelled') {
                                                    setCardCount((oldCount) => {
                                                        return oldCount.map((y, j) => {
                                                            if (i === j) {
                                                                return y > 0 ? Number(y) - 1 : 0
                                                            } else {
                                                                return y
                                                            }
                                                        })
                                                    })
                                                }
                                            }}>-</button>
                                            <input type="text" className="drops-modal__input" readOnly={dates[dateForModal.index].status[i] === 'none' || dates[dateForModal.index].status[i] === 'cancelled' ? false : true} value={cardCount != null ? cardCount[i] : 0} onChange={(e) => {
                                                if (dates[dateForModal.index].status[i] === 'none' || dates[dateForModal.index].status[i] === 'cancelled') {
                                                    if (e.target.value !== '') {
                                                        if (e.target.value.match(/^\d+$/)) {
                                                            setCardCount((oldCount) => {
                                                                return oldCount.map((y, j) => {
                                                                    if (i === j) {
                                                                        return Number(e.target.value)
                                                                    } else {
                                                                        return y
                                                                    }
                                                                })
                                                            })
                                                        }
                                                    } else {
                                                        setCardCount((oldCount) => {
                                                            return oldCount.map((y, j) => {
                                                                if (i === j) {
                                                                    return ''
                                                                } else {
                                                                    return y
                                                                }
                                                            })
                                                        })
                                                    }
                                                } else {
                                                    setCardCount((oldCount) => {
                                                        return oldCount.map((y, j) => {
                                                            if (i === j) {
                                                                return dates[dateForModal.index].count[0]
                                                            } else {
                                                                return y
                                                            }
                                                        })
                                                    })
                                                }
                                            }} />
                                            <button className="drops-modal__add" onClick={(e) => {
                                                e.preventDefault()
                                                if (dates[dateForModal.index].status[i] === 'none' || dates[dateForModal.index].status[i] === 'cancelled') {
                                                    setCardCount((oldCount) => {
                                                        return oldCount.map((y, j) => {
                                                            if (i === j) {
                                                                return Number(oldCount[i]) + 1
                                                            } else {
                                                                return y
                                                            }
                                                        })
                                                    })
                                                }
                                            }}>+</button>
                                        </div>
                                    </li>
                                </ul>
                                <button className="drops-modal__submit" style={{
                                    display: dateForModal.admin ? 'flex' : (dates[dateForModal.index].status[i][i] === 'declined' ? 'none' : 'flex'),
                                    opacity: btnEnabled ? 1 : 0.6
                                }}>
                                    {btnStatus}
                                </button>
                            </form>
                        )
                    })}
                    <p className="drops-modal__descr">
                    You must have sufficient funds in&nbsp;your wallet to&nbsp;submit the order. However, the&nbsp;funds will not be debited until your application is&nbsp;approved, and you make the&nbsp;payment.
                    </p>
                </div>
            </div>
            <SuccessModal successRef={successRef} successMode={successMode} animSuccess={animSuccess} successCount={successCount} successPrice={successPrice} name={dates[dateForModal.index].name} finishDate={finishDate} />
        </div>
    )
}