import buyNft from "../contracts/BuyNFT"
import starImg from './../assets/img/star.webp'
import walletImg from './../assets/img/wallet.webp'
import boxImg from './../assets/img/box.jpg'

export default function SuccessAdminDrop ({agreeModalDates, currentAccount, animAgreeModal, updateDropInfo, isDropList}) {
    return (
        <div className="agree modal" onClick={() => {
            animAgreeModal.current.reverse()
        }}>
            <div className="agree__container" onClick={(e) => {
                e.stopPropagation()
            }}>
                <div className="agree__top">
                    <div className="agree__left">
                        <div className="agree__block">
                            <div className="agree__text">
                                <img src={starImg} alt="" className="agree__text-img" />
                                <p className="agree__subtitle">
                                    Drop name:
                                </p>
                                <p className="agree__descr">
                                    {agreeModalDates.dropName}
                                </p>
                            </div>
                            <div className="agree__text">
                                <img src={starImg} alt="" className="agree__text-img" />
                                <p className="agree__subtitle">
                                    Created:
                                </p>
                                <p className="agree__descr">
                                    {agreeModalDates.start}
                                </p>
                            </div>
                        </div>
                        <div className="agree__block">
                            <div className="agree__text">
                                <img src={starImg} alt="" className="agree__text-img" />
                                <p className="agree__subtitle">
                                    Finish date:
                                </p>
                                <p className="agree__descr">
                                    {agreeModalDates.finish}
                                </p>
                            </div>
                            <div className="agree__text">
                                <img src={starImg} alt="" className="agree__text-img" />
                                <p className="agree__subtitle">
                                    Next sale:
                                </p>
                                <p className="agree__descr">
                                    {agreeModalDates.nextDrop}
                                </p>
                            </div>
                        </div>
                        <div className="agree__block">
                            <div className="agree__text">
                                <img src={walletImg} alt="" className="agree__text-img" />
                                <p className="agree__subtitle">
                                    Min need:
                                </p>
                                <p className="agree__descr">
                                    {agreeModalDates.minNeed}
                                </p>
                            </div>
                            <div className="agree__text">
                                <img src={walletImg} alt="" className="agree__text-img" />
                                <p className="agree__subtitle">
                                    Max need total:
                                </p>
                                <p className="agree__descr">
                                    {agreeModalDates.maxNeed}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="agree__right agree__tooltip">
                        <p className="agree__tooltip-descr">
                            {agreeModalDates.tooltip}
                        </p>
                    </div>
                </div>
                <ul className="agree__boxs">
                    {isDropList.map((x, i) => {
                        return (
                            <li key={i} className="agree__box" >
                                <div className="agree__box-top">
                                    <div className="agree__box-img" style={{
                                        backgroundImage: x.image == null ? `url(${boxImg})` : `url(${x.image})`
                                    }} />
                                </div>
                                <div className="agree__box-block">
                                    <p className="agree__box-subtitle">
                                        Name:
                                    </p>
                                    <p className="agree__box-descr">
                                        {agreeModalDates.boxNames[i]}
                                    </p>
                                </div>
                                <div className="agree__box-block agree__box-block--descr">
                                    <p className="agree__box-subtitle">
                                        Description:
                                    </p>
                                    <p className="agree__box-descr agree__box-descr--scroll">
                                        {agreeModalDates.boxDescrs[i]}
                                    </p>
                                </div>
                                <div className="agree__box-block">
                                    <p className="agree__box-subtitle">
                                        Price categories:
                                    </p>
                                    <p className="agree__box-descr">
                                        {x.price[0]}, {x.price[1]}, {x.price[2]} USDT
                                    </p>
                                </div>
                                <div className="agree__box-block agree__box-block--counter">
                                    <p className="agree__box-descr">
                                        {agreeModalDates.boxVillages[i]} Villages {agreeModalDates.boxCharacters[i]} Characters
                                    </p>
                                </div>
                            </li>
                        )
                    })}
                </ul>
                <div className="agree__btns">
                    <button className="agree__btn agree__submit" onClick={async () => {
                        await buyNft.incrementMint(agreeModalDates.dataContract, currentAccount)
                        await updateDropInfo()
                        animAgreeModal.current.reverse()
                    }}>
                        Deploy
                    </button>
                    <button className="agree__btn agree__cancel" onClick={() => {
                        animAgreeModal.current.reverse()
                    }}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}