import gsap from "gsap"
import { useEffect, useRef, useState } from "react"

import village1 from './../assets/img/village1.png'
import character1 from './../assets/img/character1.png'

export default function GenCard({i, openAll, data}) {

    const [open, setOpen] = useState(false)
    
    const rareCollor = () => {
        switch (data.attributes[2].value) {
            case 'Common':
                return {
                    border: 'linear-gradient(90deg, #6B6BFF 0%, #0003DC 49.04%, #6A6AFF 100%)',
                    bg: 'rgba(75, 75, 217, 0.65)',
                    type: 'common'
                }
            case 'Rare':
                return {
                    border: 'linear-gradient(90deg, #FF7A70 0%, #D50A1B 48.81%, #FF7970 100%)',
                    bg: 'rgba(224, 43, 30, 0.65)',
                    type: 'rare'
                }
            case 'Epic':
                return {
                    border: 'linear-gradient(90deg, #E26CFE 0%, #AB0BD4 49.25%, #DD68E3 100%)',
                    bg: 'rgba(183, 100, 203, 0.65)',
                    type: 'epic'
                }
            case 'Legendary':
                return {
                    border: 'linear-gradient(90deg, #FDCD6B 0%, #8E6211 52.6%, #F9CA6D 100%)',
                    bg: 'rgba(253, 205, 107, 0.5)',
                    type: 'legendary'
                }
            default:
                return {
                    border: 'linear-gradient(90deg, #737373 0%, #C4C4C4 49.04%, #717174 100%)',
                    bg: 'rgba(152, 152, 152, 0.65)',
                    rare: 'none'
                }
        }
    }

    useEffect(() => {
        if (!openAll) setOpen(false)
    }, [openAll])

    return (
        <li key={i} className={`open-modal__card ${openAll || open ? 'open-modal__card--open' : ''}`} onClick={() => {
            if (!openAll) (
                setOpen(true)
            )
        }}>
            <div className="open-modal__card-front">
                <div className="open-modal__card-front-border--default">
                    <div className="open-modal__card-front--default">
                        <img className="logo__img" src="/assets/logo.svg" alt="Logo" />
                    </div>
                </div>
                <div className={`open-modal__card-front-border--type open-modal__card-front-border--${rareCollor().type}`} style={{
                    background: rareCollor().border
                }}>
                    <div className={`open-modal__card-front--type open-modal__card-front--${rareCollor().type}`} style={{
                        background: rareCollor().bg
                    }}>
                        <img className="logo__img" src="/assets/logo.svg" alt="Logo" />
                    </div>
                </div>
            </div>
            <div className="open-modal__card-back" style={{
                backgroundImage: `url(${data.image})`
            }}>

            </div>
        </li>
    )
}